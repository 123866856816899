import React from 'react';

const TextImageCurve = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M0,0 C21.3817567,18.7383869 32.0726351,41.3310553 32.0726351,67.7780052 C32.0726351,94.2249551 21.3817567,116.96562 0,136 L32.9528587,136 L32.9528587,0 L0,0 Z"
      fill="#00ad6d"
    />
  </g>
);

export default TextImageCurve;
