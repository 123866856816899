import { palette } from '../palette/Palette';

const Toaster = {
  wrapper: {
    backgroundColor: palette.default.white
  },

  verticalLine: {
    borderColor: palette.primary.first
  },

  closeSign: {
    '& g': {
      fill: palette.primary.first
    }
  },

  bodyLink: {
    color: palette.primary.first
  },

  bodyText: {
    color: palette.grey.second
  },

  progress: {
    backgroundColor: palette.default.white
  },

  bar: {
    backgroundColor: palette.primary.first
  }
};

export default Toaster;
