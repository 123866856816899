import { palette } from '../../palette/Palette';

const PlayStatusIndicator = {
  wrapper: {
    // stations list styles, station.indicatorColor
    color: palette.primary.first
  }
};

export default PlayStatusIndicator;
