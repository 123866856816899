import React from 'react';

const iconPlay = (
  <>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="32" cy="32" r="32" />
      <circle fill="#00D473" cx="32" cy="32" r="32" />
      <g fill="#FFF">
        <path d="M46.458 30.608 26.174 17.274a1.666 1.666 0 0 0-2.557 1.102l-4.716 26.667a1.669 1.669 0 0 0 .661 1.64 1.676 1.676 0 0 0 1.764.122l25-13.334a1.665 1.665 0 0 0 .132-2.863" />
      </g>
    </g>
  </>
);

export default iconPlay;
