import { palette } from '../../palette/Palette';

const CarouselNavigationButton = {
  button: {
    // Last Listened Stations Styles navButtonBackgroundColor
    backgroundColor: palette.primary.first,

    // Last Listened Stations Styles navButtonIconColor
    color: palette.default.white
  },
  // Last Listened Stations Styles hoverColor
  hoverColor: {
    backgroundColor: palette.secondary.first
  }
};

export default CarouselNavigationButton;
