import { palette } from '../palette/Palette';

const ProgramSection = {
  week: {
    padding: 0,
    marginBottom: 20
  },
  day: {
    margin: '0',
    paddingRight: '20px',
    textTransform: 'capitalize',
    transition: 'color 0.15s ease-in-out'
  },
  weekSm: {
    width: '100%'
  },
  daySm: {
    paddingRight: '26px'
  },

  dayMd: {
    paddingRight: '15px'
  },
  containerMd: {
    padding: 0,
    width: '150%'
  },
  containerSm: {
    height: 170,
    left: '-5%'
  },
  innerContainer: {
    paddingTop: 40
  },
  innerContainerMd: {
    paddingTop: 23
  },
  innerContainerSm: {
    paddingTop: 29
  },
  sectionTitle: {
    textTransform: 'uppercase'
  },
  title: {
    margin: '0 0 24px',
    textTransform: 'capitalize',
    '&$inactive': {
      color: palette.primary.first,
      '&:hover': {
        color: palette.secondary.first
      }
    },
    '&$active': {
      color: palette.primary.second
    }
  },
  titleMd: {
    textTransform: 'capitalize'
  },
  titleSm: {
    margin: '0 0 28px'
  },
  image: {
    top: '50%',
    left: '30%',
    display: 'block',
    transform: 'translate(-30%, -50%)',
    height: 'auto',
    width: '100%'
  },
  imageMd: {
    maxHeight: 200
  },
  imageSm: {
    maxHeight: 172
  }
};

export default ProgramSection;
