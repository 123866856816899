import { palette } from '../palette/Palette';

const UniversalVotingMainScreen = {
  tabLinkText: {
    color: palette.default.white
  },

  activeSm: {
    paddingBottom: 14
  },

  selectionIndicator: {
    backgroundColor: palette.secondary.second,
    color: palette.default.white
  },

  userListFixedHeader: {
    backgroundColor: palette.grey.seventh
  }
};

export default UniversalVotingMainScreen;
