import { palette } from '../palette/Palette';

const FAQComponent = {
  answer: {
    color: palette.secondary.first
  },

  title: {},

  accordion: {
    padding: '21px 0 0 0 '
  },

  icon: {
    width: 32,
    height: 32,
    right: '40px'
  },

  iconMd: {
    right: 30
  },

  iconSm: {
    width: 24,
    height: 24,
    right: '20px'
  },

  iconOpen: {
    width: 32,
    height: 32,
    right: '40px',
    '& g': {
      fill: palette.primary.first
    }
  },

  iconOpenMd: {
    right: 30
  },

  iconOpenSm: {
    width: 24,
    height: 24,
    right: '20px'
  },

  accordionTitle: {
    padding: '30px 80px 30px 40px',
    '&:hover': {
      '& $icon': {
        '& path': {
          fill: palette.secondary.first
        }
      },
      '& $iconOpen': {
        '& path': {
          fill: palette.secondary.first
        }
      }
    }
  },

  accordionTitleSm: {
    padding: '30px 80px 30px 20px'
  },

  accordionBody: {
    padding: '0px 40px 58px'
  },

  accordionBodyMd: {
    padding: '0px 40px 40px'
  },

  accordionBodySm: {
    padding: '0px 20px 20px'
  },

  accordionItemExpanded: {
    borderColor: palette.grey.sixth
  },

  accItemTitle: {
    color: palette.default.black,
    width: 737
  },

  accItemTitleMd: {
    width: 606
  },

  accItemTitleSm: {
    width: 271
  }
};

export default FAQComponent;
