import { palette } from '../palette/Palette';

const CampaignImageSection = {
  root: {
    '&:hover': {
      '& button': {
        backgroundColor: palette.secondary.third
      }
    }
  },
  teaserText: {
    textTransform: 'lowercase',
    '& ::first-letter': {
      textTransform: 'uppercase'
    }
  },

  subText: {
    textTransform: 'lowercase',
    '& ::first-letter': {
      textTransform: 'uppercase'
    }
  }
};

export default CampaignImageSection;
