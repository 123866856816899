import React from 'react';

const iconChevronLeft = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <g fill="#FFF">
      <path
        d="M64.735 10v43.135c0 .925-.56 1.66-1.485 1.66h-6.5c-.925 0-1.485-.735-1.485-1.66v-33.73h-33.78c-.93 0-1.485-.55-1.485-1.66V11.66c0-.92.555-1.66 1.485-1.66h33.78l9.465.005c.003 0 .005-.002.005-.005z"
        transform="rotate(-135 42.368 32.398)"
      />
    </g>
  </g>
);

export default iconChevronLeft;
