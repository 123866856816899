import { palette } from '../palette/Palette';

const ListItems = {
  iconSize: {
    width: 16,
    height: 16
  },

  background: {},

  textHolder: {
    color: palette.grey.eight
  },

  itemHolder: {},

  itemHolderArticle: {},

  itemMobileHolder: {
    backgroundColor: palette.default.white
  },

  masonryItemHolder: {
    backgroundColor: palette.default.white,

    '&:hover': {
      backgroundColor: palette.grey.fifth
    }
  },

  masonryTitleHover: {
    color: palette.primary.first
  },

  listImageMasonry: {
    width: '27%'
  },

  masonryIconSize: {
    width: 14,
    height: 14
  },

  listImageMasonryMd: {
    width: '23.5%'
  },

  icon: {
    bottom: 0,
    right: 0
  },

  longListImageHolder: {
    width: '27%'
  },

  longListImageMasonryMd: {
    width: '27%'
  },

  iconHolder: {
    padding: '0 0 2px 0'
  },
  imageTextItemHolder: {
    background: palette.gradient.secondListItem,
    '& $textHolder': {
      backgroundColor: 'unset'
    },
    '& $imageHolder': {
      borderRadius: 0
    },
    '& $imageHolderInner': {
      borderRadius: 0
    },
    '& span': {
      color: palette.default.white
    },
    '& $icon': {
      right: 6,
      bottom: 6,
      '& svg': {
        '& circle': {
          fill: palette.secondary.second
        }
      }
    }
  },
  listItemArticle: {
    '&:hover': {
      '& span': {
        color: palette.primary.first
      },
      '& svg': {
        width: 48,
        height: 48
      }
    }
  }
};

export default ListItems;
