import { palette } from '../../palette/Palette';

const Playlist = {
  showMore: {
    // Playlist History Styles linkColor
    color: palette.primary.first,
    // Playlist History Styles linkFontFace
    fontFamily: 'UniNeueBold',
    // Playlist History Styles linkFontSize
    fontSize: 18,
    // Playlist History Styles linkLineHeight
    lineHeight: 1,
    // Playlist History Styles linkFontStyle
    textTransform: 'none'
  },

  hover: {
    // Playlist History Styles linkHoverColor
    color: palette.secondary.first
  },

  hoverAfter: {
    // Playlist History Styles linkHoverColor
    backgroundColor: palette.secondary.first
  },

  before: {
    // Playlist History Styles timestampColor
    borderLeft: `dotted 2px ${palette.grey.second}`
  },

  after: {
    // Playlist History Styles linkColor
    backgroundColor: palette.primary.first
  },

  trackPlayTime: {
    // Playlist History Styles timestampColor
    color: palette.grey.second,
    // Playlist History Styles timestampFontFace
    fontFamily: 'PoppinsRegular',
    // Playlist History Styles timestampFontSize
    fontSize: 13,
    // Playlist History Styles timestampLineHeight
    lineHeight: 1.08
  },

  nowPlaying: {
    // Playlist History Styles timestampAccentColor
    color: palette.default.black,
    // Playlist History Styles timestampFontFace
    fontFamily: 'PoppinsBold',
    // Playlist History Styles timesstampAccentFontSize
    fontSize: 14,
    // Playlist History Styles timestampLineHeight
    lineHeight: 1.43
  },
  listName: {
    backgroundColor: palette.primary.first
  },
  trackNumberContainer: {
    backgroundColor: palette.primary.first
  }
};

export default Playlist;
