import { palette } from '../palette/Palette';

const SearchFragmentJackpot = {
  fragmentJackpotWrapperOuter: {
    color: palette.grey.sixth
  },

  iconPicker: {
    width: 21,
    height: 13,
    paddingTop: 15,
    transform: 'rotate(-90deg)'
  },

  iconPickerMd: {
    marginLeft: 15
  }
};

export default SearchFragmentJackpot;
