import { palette } from '../palette/Palette';

const UniversalVotingTrackList = {
  loadMore: {
    '& p': {
      color: palette.default.white
    }
  },

  spinner: {
    borderTop: `14px solid ${palette.primary.first}`
  },

  next: {
    '& a': {
      color: palette.primary.first
    }
  },

  prev: {
    '& a': {
      color: palette.primary.first
    }
  }
};

export default UniversalVotingTrackList;
