import { palette } from '../palette/Palette';

const UniversalVotingModal = {
  modalHeader: {
    backgroundImage: palette.gradient.second
  },

  closeIcon: {
    '& g': {
      fill: palette.default.white
    }
  }
};
export default UniversalVotingModal;
