import { palette } from '../palette/Palette';

const Category = {
  tag: {
    padding: '6px 12px',
    backgroundColor: palette.primary.first,

    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  categoryMobile: {
    '& span': {
      color: palette.primary.first
    },
    textTransform: 'uppercase',
    paddingBottom: 5
  },

  tagMd: {},

  tagSm: {
    paddingTop: 0,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 6
  }
};

export default Category;
