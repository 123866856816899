const TabPanel = {
  list: {
    // General Styles second value gutter
    padding: '0 30px',
    // Tabbed Navigation Style borderColor (doesn't exist in palette)
    borderBottomColor: '#ececec',
    // Tabbed Navigation Style backgroundColor
    backgroundColor: 'transparent'
  },
  listMd: {
    // General Styles second value gutterSmall
    padding: '0 20px'
  },
  bodySm: {
    // General styles only second value gutterSmall=30
    padding: '0 20px'
  }
};

export default TabPanel;
