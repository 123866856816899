import { useEffect } from 'react';
import { getAccessToken } from '@talpanetwork/talpa-publishing-components/TalpaAuth';

import { SkeletonSelector } from '@components/SkeletonSelector/SkeletonSelector';
import * as PageTypes from '@constants/constants';

const AuthInterceptor = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (!code) return;
    getAccessToken(code);
  }, []);

  // TODO-AUTH: fix the loader based on page type
  return <SkeletonSelector type={PageTypes.OVERVIEW} />;
};

export default AuthInterceptor;
