import { Gigya } from '../imports';

import config from '../config';

const newsletterId = process.env.NEXT_PUBLIC_SCREENSET_NEWSLETTER;
const loginId = process.env.NEXT_PUBLIC_SCREENSET_LOGIN;
const profileId = process.env.NEXT_PUBLIC_SCREENSET_PROFILE;
const containerId = process.env.NEXT_PUBLIC_SCREENSET_CONTAINER;

const gigya = new Gigya(
  config.getGigyaBrandName(),
  newsletterId,
  loginId,
  profileId,
  containerId
);

export default gigya;
