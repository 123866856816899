import React from 'react';

const grabIcon = (
  <g fill="#D0D0D0" fillRule="evenodd">
    <rect width="3.2" height="3.25" x="3.2" y="3.25" rx=".5" />
    <rect width="3.2" height="3.25" x="9.6" y="3.25" rx=".5" />
    <rect width="3.2" height="3.25" x="9.6" y="11.375" rx=".5" />
    <rect width="3.2" height="3.25" x="9.6" y="19.5" rx=".5" />
    <rect width="3.2" height="3.25" x="3.2" y="19.5" rx=".5" />
    <rect width="3.2" height="3.25" x="3.2" y="11.375" rx=".5" />
  </g>
);

export default grabIcon;
