import { palette } from '../palette/Palette';

const UniversalVotingUserList = {
  content: {
    backgroundColor: palette.grey.tenth
  },
  userListTab: {
    '& p': {
      fontFamily: 'Radio10Regular',
      fontSize: 21,
      color: palette.default.white
    }
  },

  additionalTrack: {
    borderColor: palette.grey.first
  },

  text: {
    fontFamily: 'PoppinsRegular'
  },

  smallHeader: {
    color: palette.default.white
  },

  userListTabMd: {
    padding: 0,
    backgroundColor: 'transparent',
    '& p': {
      fontFamily: 'PoppinsBold',
      color: palette.default.white
    }
  }
};

export default UniversalVotingUserList;
