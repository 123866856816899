import { palette } from '../palette/Palette';

const VideoSectionSkeleton = {
  category: {
    backgroundColor: palette.primary.first
  },

  lightGreyBox: {
    backgroundColor: palette.grey.fifth
  },

  middleGreyBox: {},

  darkGreyBox: {
    backgroundColor: palette.grey.third
  }
};

export default VideoSectionSkeleton;
