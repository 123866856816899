import { palette } from '../palette/Palette';

const Badge = {
  root: {},

  exclusiveMd: {
    padding: '6px 15px'
  },

  newEpisode: {
    padding: '6px 8px',
    backgroundColor: palette.secondary.second
  },

  programGuide: {
    top: 16,
    right: 16,
    backgroundColor: palette.secondary.second,
    padding: '6px 8px'
  },

  programGuideMd: {
    top: 8,
    right: 8,
    padding: '3px 6px'
  },

  programGuideSm: {
    padding: '2px 6px'
  }
};

export default Badge;
