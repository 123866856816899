import { palette } from '../../palette/Palette';

const PopoutButton = {
  buttonWrapperHover: {
    // Media Switcher Styles hoverColor
    fill: palette.secondary.first
  }
};

export default PopoutButton;
