import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconStop = (
  <>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 -.026)">
        <circle cx="32" cy="32" r="32" />
        <circle fill={palette.primary.first} cx="32" cy="32" r="32" />
      </g>
      <g transform="translate(17.938 17.938)">
        <rect width="28.125" height="28.125" rx="2" />
        <rect
          fill={palette.default.white}
          width="28.125"
          height="28.125"
          rx="2"
        />
        <rect width="28.125" height="28.125" rx="2" />
      </g>
    </g>
  </>
);

export default iconStop;
