import { palette } from '@theme/palette/Palette';

const UniversalVotingScrollToTop = {
  scrollTopButton: {
    paddingTop: 16,
    paddingBottom: 16,
    '& svg': {
      fill: palette.default.white
    }
  },
  scrollTopButtonMd: {
    paddingTop: 15,
    paddingBottom: 15
  },
  scrollTopButtonSm: {
    paddingTop: 14,
    paddingBottom: 14
  }
};

export default UniversalVotingScrollToTop;
