import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import config from '../config';
import 'isomorphic-unfetch';

const uri = config.getUniversalApiUrl();

const universalApiKey = config.getUniversalApiKey();

const headers =
  typeof universalApiKey === 'string'
    ? { 'x-api-key': universalApiKey }
    : undefined;

export const client = new ApolloClient({
  link: new HttpLink({
    uri,
    headers,
    useGETForQueries: true
  }),
  cache: new InMemoryCache()
});
