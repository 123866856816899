import { palette } from '../../palette/Palette';

const StationRow = {
  title: {
    // stations list styles headingColor
    color: palette.grey.second,
    // stations list styles headingFontFace
    fontFamily: 'Radio10Regular',
    // stations list headingFontSize
    fontSize: 16,
    // stations list headingLineHeight
    lineHeight: 1.5,
    // stations list headingFontStyle
    textTransform: 'none'
  }
};

export default StationRow;
