import { palette } from '../palette/Palette';

const Frequency = {
  holder: {
    verticalAlign: 'middle',
    paddingTop: 80
  },
  content: {
    verticalAlign: 'top',
    paddingRight: '1.6%'
  },
  freqImage: {
    verticalAlign: 'top',
    paddingLeft: '1.6%'
  },
  title: {
    margin: '0 0 20px',
    color: palette.default.black
  },
  inputOuter: {
    margin: '50px 0 0'
  },
  inputHolder: {
    maxWidth: '580px',
    borderWidth: 20,
    borderStyle: 'solid',
    borderColor: palette.primary.first,
    boxShadow: '0 2px 30px 0 rgba(0, 0, 0, 0.1)'
  },
  inputTypeText: {
    padding: '12px 40px 12px 16px',
    lineHeight: 'inherit',
    backgroundColor: palette.default.white,
    color: palette.default.black,
    '&:hover': {
      outline: `solid 2px ${palette.grey.first}`
    },
    '&::placeholder': {
      color: palette.grey.second
    },
    '&::-ms-input-placeholder': {
      color: palette.grey.second
    },
    '&:-ms-input-placeholder': {
      color: palette.grey.second
    }
  },
  infoList: {
    list: {
      color: palette.primary.first
    },
    link: {
      color: palette.secondary.first
    }
  },
  dropdownList: {
    backgroundColor: palette.grey.fifth,
    '& $freqItem': {
      color: palette.default.black
    }
  },
  dropdownItem: {
    color: palette.default.black,
    '&:hover': {
      '& $freqItem': {
        color: palette.primary.first
      }
    }
  },
  zipResults: {
    padding: '40px 0 0'
  },
  location: {
    verticalAlign: 'bottom',
    color: palette.default.black
  },
  locationIcon: {
    verticalAlign: 'middle',
    '& svg': {
      fill: palette.secondary.second
    }
  },
  pinIcon: {
    width: 32,
    height: 32
  },
  locationText: {
    verticalAlign: 'middle',
    paddingLeft: 42,
    '& $stationLocation': {
      color: palette.grey.second
    }
  },
  frequency: {
    textAlign: 'right',
    verticalAlign: 'bottom'
  },
  searchClear: {
    right: 20,
    '& $clearText': {
      color: palette.grey.second
    }
  },
  searchIcon: {
    right: 36,
    top: -4,
    height: '41.6px',
    marginTop: -7.5,
    '& svg': {
      fill: palette.grey.third
    }
  },
  searchIconSm: {
    marginTop: -9.5
  },
  tableBottom: {
    marginTop: '100px'
  },
  infoItem: {
    verticalAlign: 'top',
    paddingRight: '1.6%'
  },
  infoTitle: {
    padding: '0 0 10px'
  },
  freqItem: {
    color: palette.default.white,
    padding: '12px 0 12px 16px'
  },
  linkText: {
    padding: '26px 0 0',
    '& p': {
      color: palette.secondary.second
    },
    '& svg': {
      fill: palette.secondary.second
    },
    '&:hover': {
      color: palette.secondary.third,
      '& svg:first-of-type': {
        fill: palette.secondary.third
      },
      '& p:first-of-type': {
        color: palette.secondary.third
      }
    }
  },
  linkTextMd: {
    padding: '20px 0 0'
  },
  svgLink: {
    float: 'right'
  },
  linkIcon: {
    width: 20,
    height: 20
  },
  textLink: {
    paddingRight: 10,
    textTransform: 'none'
  },
  contentMd: {
    padding: 0
  },
  holderMd: {
    paddingTop: 64
  },
  inputOuterMd: {
    margin: '40px 0 0'
  },
  zipResultsSm: {
    padding: '30px 0 0'
  },
  searchIconMd: {
    paddingLeft: '25px'
  },
  tableBottomMd: {
    marginTop: '80px'
  },
  holderSm: {
    padding: '0 20px',
    paddingTop: 48
  },
  titleSm: {
    margin: '0 0 20px'
  },
  tableBottomSm: {
    marginTop: '60px'
  },
  infoItemSm: {
    padding: '0',
    paddingBottom: '45px'
  },
  searchSvg: {
    width: 24,
    height: 24
  },
  locationTypography: {
    marginBottom: 15
  }
};

export default Frequency;
