import { palette } from '../palette/Palette';

const LoginOverlay = {
  outerHolder: {
    zIndex: 5,
    height: 400,
    backgroundImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 17%, #ffffff 45%, #ffffff)',
    '& $button': {
      minWidth: 110,
      minHeight: 52,
      textAlign: 'center',
      marginRight: 16
    },

    '& $registerButton': {
      backgroundColor: palette.grey.seventh,
      border: `1px solid ${palette.grey.seventh}`,
      minWidth: 110,
      minHeight: 52,
      '&:hover': {
        backgroundColor: palette.primary.second
      }
    }
  },

  textHolder: {
    padding: '0 40px',
    margin: '24px auto'
  },

  registerButton: {
    '& $buttonTextRegister': {
      color: palette.default.white
    }
  },

  buttonText: {
    color: palette.default.white,
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },

  buttonTextRegister: {
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },

  textHolderMd: {
    maxWidth: 350,
    padding: '0 32px'
  },

  outerHolderMd: {
    paddingTop: 32,
    '& $button': {
      minWidth: 88,
      minHeight: 42
    },

    '& $registerButton': {
      minWidth: 88,
      minHeight: 42
    }
  },

  textHolderSm: {
    maxWidth: 311,
    padding: '0 16px',
    margin: '16px auto'
  },

  outerHolderSm: {
    height: 300,
    paddingTop: 24,
    '& $button': {
      minWidth: 74,
      minHeight: 32
    },

    '& $registerButton': {
      minWidth: 74,
      minHeight: 32
    }
  },

  iconsHolder: {
    '& svg': {
      width: 140,
      height: 24
    }
  },

  iconsHolderSm: {
    '& svg': {
      width: 95,
      height: 20
    }
  },

  // vriend van 10
  iconViewBox: '0 0 176 30'
};

export default LoginOverlay;
