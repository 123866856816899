const Lock = {
  iconMemberSvg: {
    width: 80,
    height: 17,
    viewBox: '0 0 176 30'
  },
  iconMemberTopSvg: {
    width: 180,
    height: 64,
    viewBox: '0 0 180 64'
  },
  iconMemberBottomSvg: {
    width: 180,
    height: 64,
    viewBox: '0 0 180 64'
  }
};

export default Lock;
