import { palette } from '../palette/Palette';

const ChartList = {
  chartListTop: {
    backgroundColor: palette.default.white
  },
  chartListSearch: {
    backgroundColor: palette.default.white
  },

  chartListHeaderContainer: {
    backgroundColor: palette.primary.first,
    color: palette.default.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    height: 50
  },

  chartListHeaderContainerMd: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },

  chartListTableRow: {
    height: 75,
    borderBottomWidth: 0,
    oddColor: palette.default.white,
    evenColor: palette.grey.fifth
  },

  chartListTableRowMd: {
    height: 60
  },

  chartListHeaderRow: {
    borderBottom: `1px solid ${palette.grey.third}`
  },

  chartListTableColumnPosition: {
    color: palette.primary.first
  },

  fixed: {
    chartListHeader: {
      width: '83.71%',
      maxWidth: 1200
    },

    chartListHeaderLg: {
      width: '88%'
    },

    chartListSearch: {
      padding: '0 0 23px'
    },

    headerContainer: {
      backgroundColor: palette.default.white,
      color: palette.default.black
    },

    headerTypography: {
      color: palette.default.black
    }
  },

  chartListTableWrapper: {
    color: palette.default.black
  }
};

export default ChartList;
