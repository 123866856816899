import React from 'react';

const iconNewSliderBack = (
  <g>
    <defs>
      <circle id="lx03i02bta" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <circle cx="32" cy="32" r="32" />
          <circle cx="32" cy="32" r="32" fill="#00D473" />
        </g>
        <g fill="#FFF">
          <path
            d="M30.345 4.688v20.219c0 .434-.263.778-.697.778h-3.046c-.434 0-.697-.344-.697-.778V9.097H10.071c-.436 0-.696-.259-.696-.779V5.466c0-.432.26-.779.696-.779h15.834l4.437.003.003-.002z"
            transform="translate(17 17) rotate(-135 19.86 15.186)"
          />
        </g>
      </g>
    </g>
  </g>
);

export default iconNewSliderBack;
