import { Content } from '../imports';
import { getConfig } from 'utils/config.utils';

const config = getConfig();

const content = new Content(
  config.accessToken,
  config.spaceId,
  config.host,
  config.accessTokenPreview,
  config.hostPreview,
  config.environment
);

export default content;
