import { palette } from '../palette/Palette';

const CountdownDisplay = {
  countdownWrapper: {
    color: palette.default.black
  },

  rectangle: {
    backgroundColor: palette.primary.first,
    color: palette.default.white
  },

  daysDisplayText: {
    textTransform: 'uppercase'
  },

  title: {
    fontSize: 16
  }
};

export default CountdownDisplay;
