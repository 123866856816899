import { palette } from '../palette/Palette';

const UniversalVotingShareSocialIcons = {
  instagramButton: {
    backgroundColor: palette.primary.first,
    '& p': {
      color: palette.default.white
    }
  },

  hoverInstagramButtons: {
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  iconShareInstagramSm: {
    width: 16,
    height: 16,
    padding: '8px 10px'
  }
};

export default UniversalVotingShareSocialIcons;
