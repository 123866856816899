import React from 'react';

const iconCtaArrow = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M46.7631 49.4738L64 32.2369L46.7631 15V29.2843H0V35.1895H46.7631V49.4738Z"
  />
);

export default iconCtaArrow;
