import { palette } from '../../palette/Palette';

const Scrubber = {
  startTime: {
    //  Scrubber textColor
    color: palette.grey.second,
    //  Scrubber fontFace
    fontFamily: 'UniNeueBold'
  },
  endTime: {
    //  Scrubber textColor
    color: palette.grey.second,
    //  Scrubber fontFace
    fontFamily: 'UniNeueBold'
  }
};

export default Scrubber;
