import { palette } from '../palette/Palette';

const HeaderSectionCurved = {
  textHolder: {
    padding: '9% 4% 9% 0',
    '& p': {
      color: palette.default.black
    }
  },

  textHolderMd: {
    padding: 0
  },

  logoHolder: {
    display: 'none'
  },

  title: {
    padding: '0 0 30px'
  },

  titleMd: {
    padding: '32px 0 25px 0'
  },

  titleSm: {
    padding: '24px 0 15px 0'
  },

  imageHolder: {
    padding: 0
  },

  imageTopMd: {
    display: 'block'
  },

  imageBottomMd: {
    display: 'none'
  },

  imageTopSm: {
    width: '100%',
    display: 'block',
    height: 'auto'
  },

  imageBottomSm: {},

  tableCellMd: {
    width: '100%',
    display: 'block',
    height: 'auto'
  },

  imageInner: {
    maxWidth: '774px',
    width: '130%'
  },

  imageInnerMd: {
    maxWidth: '100%',
    left: '0%',
    '& img': {
      borderRadius: 4
    }
  },

  imageInnerSm: {},

  imgOverlay: {
    top: '0',
    left: '0',
    maxWidth: '212px'
  },

  imgOverlayMd: {
    display: 'none'
  },

  link: {
    '& span': {
      color: palette.default.white
    },
    '&:hover': {
      color: palette.default.white
    }
  },

  linkMd: {
    '& button': {
      minHeight: 42
    }
  },

  linkSm: {
    '& button': {
      display: 'flex',
      justifyContent: 'center',
      minHeight: 32
    }
  },

  secondLink: {
    '& button': {
      backgroundColor: palette.grey.seventh,

      '& span': {
        color: palette.default.white
      },

      '&:hover': {
        backgroundColor: palette.primary.second
      }
    }
  },

  secondLinkMd: {
    '& button': {
      minHeight: 42
    }
  },

  secondLinkSm: {
    '& button': {
      display: 'flex',
      minHeight: 32
    }
  }
};

export default HeaderSectionCurved;
