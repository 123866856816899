import { palette } from '../palette/Palette';

const Navigation = {
  mainHeader: {
    height: 72,
    paddingRight: 7,
    backgroundImage: palette.gradient.second,
    flex: 1,
    color: palette.default.white
  },
  mainHeaderMd: {
    height: 60
  },
  menuText: {
    textTransform: 'capitalize'
  },
  searchActive: {},
  navItemLink: {
    '&:hover': {
      color: palette.grey.fourth
    }
  },

  playButton: {
    backgroundColor: palette.secondary.second,
    '&:hover': {
      background: palette.secondary.third
    }
  },

  iconPlayButton: {
    height: 14,
    width: 14,
    paddingRight: 8,
    '& g': {
      fill: palette.default.black
    }
  },

  iconPlayButtonMd: {
    height: 12,
    width: 12,
    paddingRight: 10
  },

  menuHolder: {
    '&:hover': {
      '& $searchIcon': {
        '& svg': {
          '& path': {
            fill: palette.grey.fourth
          }
        }
      },
      '& $searchText': {
        '& p': {
          color: palette.grey.fourth
        }
      }
    }
  },
  svgMenuHover: {
    fill: palette.grey.fourth
  },
  searchHolder: {
    marginRight: 23,
    '&:hover': {
      '& $searchIcon': {
        '& svg': {
          '& path': {
            fill: palette.grey.fourth
          }
        }
      },
      '& $searchText': {
        '& p': {
          color: palette.grey.fourth
        }
      }
    }
  },
  iconClose: {
    '& $path': {
      fill: palette.default.white
    }
  },
  svgLogo: {
    width: 131,
    height: 48,
    viewBox: '0 0 131 48'
  },
  svgLogoMd: {
    width: 109,
    height: 41
  },
  svgLogoSm: {
    width: 98,
    height: 36,
    viewBox: '0 0 131 48'
  },
  searchIcon: {
    fill: palette.default.black
  },
  svgSearch: {
    width: 32,
    height: 32,
    '& path': {
      fill: palette.default.white
    }
  },
  searchText: {},
  svgClose: {
    width: 32,
    height: 32,
    '& $iconClose': {
      fill: palette.primary.first
    }
  },
  searchTextTypography: {
    textTransform: 'capitalize'
  },
  svgMenu: {
    width: 32,
    height: 32
  },
  logIn: {
    width: 325
  },
  logInMd: {
    width: 60
  },
  userProfile: {
    width: 320
  },

  navItemTabletContainer: {
    order: 1,
    flex: '1 1 100%',
    width: '100%'
  },

  searchLinkOverlay: {
    left: 0,
    position: 'relative',
    marginLeft: 'auto'
  },

  navItemsTabletWrapper: {
    backgroundColor: palette.default.white
  },
  navItemsTabletWrapperMd: {
    height: 52,
    flexWrap: 'wrap'
  },
  navItemsTabletWrapperSm: {
    height: 48
  },

  logoHolderMd: {
    left: 'calc(50% + 20px)'
  },
  navMenuItemMd: {
    padding: '0 16px'
  },
  navMenuItemSm: {
    padding: '0 10px'
  }
};

export default Navigation;
