import { palette } from '../../palette/Palette';

const StationTile = {
  tile: {
    // Last Listened Stations Styles tileRoundedCorners true=5% false=0
    borderRadius: '5%'
  },
  textWrapper: {
    // hardcoded white
    color: palette.default.white
  },
  text: {
    // Stations list styles station.textColor
    // last listened textColor
    color: palette.default.white,
    // Stations list styles station.textFontFace
    fontFamily: 'Radio10Regular',
    // Stations list styles textFontSize
    fontSize: 7,
    // Stations list styles textLineHeight
    lineHeight: 1.43,
    // Stations list styles textFontStyle
    textTransform: 'none'
  },
  textLastListened: {
    // last listened stations styles  textFontSize
    fontSize: 16,
    // last listened stations styles  textFontFace
    fontFamily: 'UniNeueHeavyItalic'
  }
};

export default StationTile;
