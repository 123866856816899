import * as PageTypes from 'constants/constants';

import * as React from 'react';

import { OverviewSkeleton, ArticleSkeleton } from '@imports';

export const SkeletonSelector = (pageType: { type: string }) => {
  switch (pageType?.type) {
    case PageTypes.ARTICLE:
      return <ArticleSkeleton noRelatedArticles />;
    case PageTypes.OVERVIEW:
    case PageTypes.CATEGORY:
      return <OverviewSkeleton />;
    default:
      return null;
  }
};
