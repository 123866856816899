import React from 'react';

const iconChevronDown = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M32.3063 47.4618L6.88875 22.0442C6.34369 21.4992 6.24057 20.7361 6.78563 20.191L10.6158 16.3609C11.1609 15.8158 11.9239 15.9189 12.469 16.464L32.3446 36.3396L52.2497 16.4345C52.7977 15.8865 53.4488 15.8836 54.1029 16.5377L57.6885 20.1233C58.2306 20.6654 58.3396 21.4285 57.7916 21.9765L32.3063 47.4559C32.3047 47.4575 32.3047 47.4602 32.3063 47.4618C32.3063 47.4618 32.3063 47.4618 32.3063 47.4618Z"
  />
);

export default iconChevronDown;
