import { palette } from '../palette/Palette';

const Menu = {
  overlay: {
    backgroundColor: palette.default.black
  },
  leftMenuBefore: {
    backgroundColor: palette.default.white
  },
  svgClose: {
    width: 32,
    height: 32,
    '& g': {
      fill: palette.primary.first
    }
  },

  // the height of scrollable area is full height of viewport substracted by height of navigation
  // and height of socialIcons wrapper
  // for devices which can be rotated the height is calculate inside Menu component on tpp
  area: {
    height: 'calc(100vh - 72px - 120px)'
  },
  subMenu: {
    '& $subMenuList li p': {
      color: palette.default.white
    }
  },
  mobileIconBack: {
    '& $svgBack': {
      fill: palette.default.white,
      transform: 'rotate(180deg)'
    }
  },
  mobileIconClose: {
    '& $subMenuSvgClose': {
      '& g': {
        fill: palette.default.white
      }
    }
  },
  subMenuSvgClose: {
    width: 24,
    height: 24
  },
  subMenuSvgBack: {
    width: 24,
    height: 24
  },
  socialIconsWrapper: {
    backgroundColor: palette.default.white
  },

  iconInner: {
    width: 18,
    height: 18
  },

  transparancyGradientAtTop: {
    backgroundImage: `linear-gradient(to bottom,  ${palette.default.white}, rgba(255, 255, 255, 0))`
  },
  transparancyGradientAtBottom: {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${palette.default.white})`
  }
};

export default Menu;
