import { palette } from '../palette/Palette';

const UniversalVotingTabs = {
  active: {
    background: palette.primary.first,
    color: palette.default.white
  }
};

export default UniversalVotingTabs;
