import { palette } from '../palette/Palette';

const OverviewSkeleton = {
  greyBox: {
    backgroundColor: palette.grey.sixth
  },

  title: {
    backgroundColor: palette.grey.third
  },

  tile: {
    height: 270
  }
};

export default OverviewSkeleton;
