import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconPlus = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M58.948 26.88H38.024l3.628-20.875c.488-2.783-1.348-5.44-4.096-5.928-2.728-.484-5.368 1.365-5.852 4.149L27.76 26.88H5.052C2.26 26.88 0 29.168 0 32c0 2.828 2.26 5.12 5.052 5.12h20.924l-3.628 20.87c-.488 2.784 1.348 5.441 4.096 5.929.296.053.592.081.884.081 2.404 0 4.536-1.747 4.968-4.23l3.94-22.654h22.712c2.792 0 5.052-2.288 5.052-5.12 0-2.828-2.26-5.116-5.052-5.116"
    fill={palette.primary.first}
  />
);

export default iconPlus;
