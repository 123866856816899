export const palette = {
  primary: {
    first: '#00D473',
    second: '#000000',
    third: '#9EFE6B'
  },
  secondary: {
    first: '#007664',
    second: '#FF5F67',
    third: '#F0505F',
    fourth: '#00E0ED',
    fifth: '#00D2E6'
  },
  grey: {
    first: '#706F6F',
    second: '#9D9D9C',
    third: '#D0D0D0',
    fourth: '#E8E8E8',
    fifth: '#F8F8F8', // Used insted of #f0f0f0, #f3f3f3, #f6f6f6 and rgba(208, 208, 208) with opacity 0.15-0.25
    sixth: '#EEEEEE', // Used instead of rgba(208, 208, 208, 0.4),
    seventh: '#3C3C3B',
    eight: '#1E1E1E',
    ninth: '#E8E8E8',
    tenth: '#515151'
  },
  default: {
    white: '#FFFFFF',
    black: '#000000'
  },
  gradient: {
    first:
      'linear-gradient(to right, #007664, #00d473 25%, #00d473 50%, #00d473 75%, #9efe6b)',
    second:
      'linear-gradient(to right, #00ad6d, #00d473 31%, #00d473 50%, #00d473 70%, #45e670);',
    secondListItem:
      'linear-gradient(to right, #00ad6d, #00ad6d 31%, #00d473 50%, #00d473 70%, #45e670);'
  }
};
