import { palette } from '../palette/Palette';

const UniversalVotingAddTrack = {
  wrapper: {
    border: `solid 1px ${palette.grey.sixth}`,
    borderRadius: 0
  },

  returnButton: {
    color: palette.primary.first
  },

  divider: {
    color: palette.grey.sixth,
    backgroundColor: palette.grey.sixth
  },

  inputTypeText: {
    borderColor: palette.grey.fifth,
    backgroundColor: palette.grey.fifth,
    '&:focus': {
      outline: 'none',
      border: `1px solid ${palette.primary.first}`
    },
    '&::placeholder': {
      color: palette.grey.second
    }
  },
  freeChoiceMotivationText: {
    '& p': {
      fontFamily: 'PoppinsRegular'
    }
  }
};
export default UniversalVotingAddTrack;
