import { palette } from '@theme/palette/Palette';

const Quote = {
  quoteWrapper: {
    textAlign: 'center',
    paddingLeft: '20%',
    paddingRight: '20%',

    '& span': {
      color: palette.grey.eight
    }
  },

  quoteIconStyle: {
    margin: '0 auto',
    paddingBottom: 20
  },

  quoteIcon: {
    width: 32,
    height: 30
  },

  quoteBorder: {
    backgroundColor: palette.primary.first,
    margin: '0 auto 12px',
    width: 120,
    height: 2
  }
};

export default Quote;
