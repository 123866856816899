import { palette } from '../palette/Palette';

const VideoCard = {
  textHolder: {
    color: palette.grey.eight,
    '&:hover': {
      cursor: 'pointer'
    }
  },

  textHolderHover: {
    color: palette.grey.seventh
  },

  iconPlayHover: {
    '& g circle': {
      fill: palette.secondary.first
    }
  }
};

export default VideoCard;
