import { palette } from '../../palette/Palette';

const PlaylistModal = {
  title: {
    // General styles headingFontFace
    fontFamily: 'Radio10Regular',
    // General styles headingFontSize
    fontSize: 17,
    // General styles headingLineHeight
    lineHeight: 1.65,
    // General styles headingColor
    color: palette.grey.second,
    // General styles headingFontStyle
    textTransform: 'none'
  },
  titleSpan: {
    // initial value in General, not from Contentful
    backgroundColor: palette.default.white
  },
  modalContent: {
    // general styles second value gutter
    padding: '16px 30px'
  },
  modalContentMd: {
    // general styles second value gutterSmall
    padding: '16px 20px'
  }
};

export default PlaylistModal;
