/* eslint-disable no-dupe-keys */
import { palette } from '../palette/Palette';

const Arrow = {
  rightArrowBackground: {
    backgroundColor: palette.default.white,
    width: 100
  },

  leftArrowBackground: {
    backgroundColor: palette.default.white,
    width: 100
  },

  left: {
    left: -7,
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  right: {
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  arrowHolder: {
    backgroundColor: palette.primary.first
  },

  programGuideArrowBackgroundLeft: {
    left: 'auto',
    right: -19,
    top: -38,
    maxHeight: 32,

    '& $arrowHolder': {
      backgroundColor: 'transparent'
    },

    '& $left:first-child': {
      transform: 'translateY(-67%) rotate(180deg)'
    },

    '& g:hover': {
      fill: palette.secondary.first
    }
  },

  programGuideArrowBackgroundRight: {
    top: -38,
    right: -20,
    maxHeight: 32,
    maxWidth: 32,

    '& $arrowHolder': {
      backgroundColor: 'transparent'
    },

    '& $right:first-child': {
      transform: 'translateY(-67%) rotate(0deg)'
    },

    '& g:hover': {
      fill: palette.secondary.first
    }
  },

  programGuideArrowBackgroundLeftMl: {
    top: -37
  },

  programGuideArrowBackgroundRightMl: {
    top: -37
  },

  programGuideArrowBackgroundLeftSl: {
    top: -67
  },

  programGuideArrowBackgroundRightSl: {
    top: -67
  },

  compositeArrowBackgroundRight: {
    background:
      '-moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%)',
    // @ts-ignore
    background:
      '-webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%)',
    // @ts-ignore
    background:
      'linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%)'
  },

  compositeArrowBackgroundLeft: {
    background:
      '-moz-linear-gradient(right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%)',
    // @ts-ignore
    background:
      '-webkit-linear-gradient(right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%)',
    // @ts-ignore
    background:
      'linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%)'
  },

  arrowIcon: {
    width: 32,
    height: 32
  },

  arrow: {
    '&:hover': {
      '& circle': {
        fill: palette.secondary.first
      }
    }
  }
};

export default Arrow;
