import { palette } from '../palette/Palette';

const JackpotCounter = {
  titleJackpot: {
    padding: '0 0 18px',
    color: palette.default.black
  },

  jackpotNumWrapper: {
    backgroundColor: palette.primary.first,
    height: 96
  },

  jackpotNumWrapperMd: {
    height: 90
  },

  jackpotNumWrapperSm: {
    height: 66
  },

  titleJackpotMd: {
    padding: '30px 0 23px'
  },

  titleJackpotSm: {
    padding: '30px 0 6px'
  },

  jackpotNum: {
    padding: '29px 0'
  },

  jackpotNumSm: {
    padding: '21px 0'
  }
};

export default JackpotCounter;
