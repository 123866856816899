import React from 'react';

const iconQuote = (
  <g fillRule="evenodd">
    <g>
      <path d="M64 47.869c0 3.854-1.239 7.11-3.716 9.772-2.478 2.66-5.551 3.991-9.222 3.991-4.496 0-8.28-1.858-11.354-5.574-3.074-3.716-4.611-8.327-4.611-13.832 0-10.827 4.955-22.389 14.864-34.684C52.44 4.514 54.641 3 56.568 3c3.395 0 5.092 1.927 5.092 5.78 0 2.203-.963 4.772-2.89 7.708-5.23 7.891-7.845 12.984-7.845 15.278 0 1.376 1.009 2.34 3.028 2.89C60.65 36.49 64 40.896 64 47.869zm-35.097 0c0 3.854-1.238 7.11-3.716 9.772-2.477 2.66-5.551 3.991-9.221 3.991-4.496 0-8.281-1.858-11.355-5.574C1.537 52.342 0 47.731 0 42.226c0-10.92 4.955-22.48 14.865-34.684C17.342 4.514 19.545 3 21.47 3c3.395 0 5.092 1.927 5.092 5.78 0 2.203-1.009 4.772-3.028 7.708-5.23 7.708-7.845 12.8-7.845 15.278 0 1.376 1.056 2.34 3.166 2.89 6.698 1.743 10.047 6.148 10.047 13.213z" />
    </g>
  </g>
);

export default iconQuote;
