import { palette } from '../palette/Palette';

const Clips = {
  linkIcon: {
    padding: '0 0 0 2px',
    width: 20,
    height: 20,
    transform: 'translateY(10%)'
  },

  linkIconMd: {
    transform: 'translateY(20%)'
  },

  linkIconSm: {
    transform: 'translateY(-5%)'
  },

  textWrapper: {
    padding: '0 0 32px'
  },

  textWrapperMd: {
    padding: '0 0 19px'
  },

  textWrapperSm: {
    padding: '0 0 20px'
  },

  seeMore: {
    color: palette.secondary.first,
    '& svg': {
      fill: palette.secondary.first
    }
  },

  seeMoreSm: {
    display: 'flex'
  },

  titleWrapper: {
    color: palette.default.black
  },

  seeMoreTitleSm: {
    textTransform: 'lowercase',
    '& ::first-letter': {
      textTransform: 'uppercase'
    }
  }
};

export default Clips;
