import { palette } from '../palette/Palette';

const NavigationSkeleton = {
  wrapper: {
    backgroundImage: palette.gradient.second
  },

  logo: {
    viewBox: '0 0 131 48'
  }
};

export default NavigationSkeleton;
