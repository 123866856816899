import React from 'react';

const universalVotingIconPause = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    id="assets"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="assets/station-no-cover-Radio10-1"
      transform="translate(-37.000000, -41.000000)"
      fill="#FFFFFF"
    >
      <g
        id="radio-10-/-icons-/-general-/-pause"
        transform="translate(22.000000, 22.000000)"
      >
        <path
          d="M54.5780697,19.80072 C55.1090697,19.80072 55.6148697,20.03472 55.9568697,20.44332 C56.2988697,20.85012 56.4428697,21.38832 56.3510697,21.91212 L56.3510697,21.91212 L51.2714697,50.71212 C51.1202697,51.57252 50.3732697,52.20072 49.5002697,52.20072 L49.5002697,52.20072 L38.7002697,52.20072 C38.1674697,52.20072 37.6616697,51.96492 37.3196697,51.55632 C36.9794697,51.14952 36.8336697,50.61132 36.9272697,50.08752 L36.9272697,50.08752 L42.0050697,21.28752 C42.1562697,20.42712 42.9050697,19.80072 43.7780697,19.80072 L43.7780697,19.80072 Z M32.9778897,19.8 C33.5088897,19.8 34.0146897,20.0358 34.3566897,20.4426 C34.6986897,20.8494 34.8426897,21.3876 34.7508897,21.9132 L34.7508897,21.9132 L29.6730897,50.7132 C29.5200897,51.5718 28.7730897,52.2 27.9000897,52.2 L27.9000897,52.2 L17.1000897,52.2 C16.5672897,52.2 16.0632897,51.9642 15.7212897,51.5574 C15.3792897,51.1506 15.2352897,50.6124 15.3270897,50.0868 L15.3270897,50.0868 L20.4048897,21.2868 C20.5578897,20.4282 21.3048897,19.8 22.1778897,19.8 L22.1778897,19.8 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </g>
);

export default universalVotingIconPause;
