export const ACCOUNT_ACTION = 'account-action';
export const RESET_PASSWORD = 'reset-password';
export const ACTIVATION = 'activation';
export const ERROR_CODE = 'errorCode';
export const BRAND_TITLE = 'Radio 10';

export const CUSTOM_NO_RESULTS = 'Er is nog geen naam genoemd';
export const CUSTOM_SEARCH = 'Zoek genoemde naam';
export const LOAD_MORE_TEXT = 'Meer resultaten';
export const RESULTS = 'zoekresultaten';
export const LOAD = 'Laden';
export const FEATURED_ITEMS = 'Uitgelichte items';

// constants from SN that should be deleted if we are not using them
export const PAGE = 'pagina';
export const E_COMMERCE_ID = 'eCommerce';
// in this order links will be rendered
export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/radio10nl/',
  twitter: 'https://twitter.com/radio10nl',
  instagram: 'https://www.instagram.com/radio10nl/',
  youtube: 'https://www.youtube.com/channel/UCuRbYBXd5yjdWJ6YYwtAAMQ'
};
export const SEARCH = 'SEARCH';
export const ARTICLE = 'ARTICLE';
export const OVERVIEW = 'OVERVIEW';
export const CATEGORY = 'CATEGORY';
export const NOT_FOUND = 'NOT_FOUND';
export const REDIRECTION = 'REDIRECTION';
export const ERROR_500 = 'ERROR_500';
export const APP_PAGE = 'APP_PAGE';
export const LATEST_ARTICLES_TITLE = 'NET BINNEN';
export const ACCOUNT = 'Account';
export const SIDEBAR_TABS = {
  trending: 1,
  latest: 2
};

export const HOME = 'HOME';
export const PROFILE_SETTINGS = 'Profiel beheren';
export const LOGOUT = 'Uitloggen';
export const RADIOPOPUP_FORBIDDEN_LINKS = [
  '/radio-luisteren',
  '/non-stop',
  '/60s-en-70s-hits',
  '/80s-hits',
  '/90s-hits',
  '/disco-classics',
  '/love-songs',
  '/de-top-4000',
  '/404',
  '/login',
  '/register',
  '/optin'
];
