import { palette } from '../../palette/Palette';

const VolumeButton = {
  wrapper: {
    // Controls Styles secondaryButtonSize
    width: 45
  },
  button: {
    // Controls Styles secondaryButtonSize
    width: 45,

    // Controls Styles secondaryButtonSize
    height: 45,

    // Controls Styles secondaryButtonColor
    color: palette.grey.seventh
  },
  // Controls Styles primaryButtonHoverColor
  hoverColor: {
    color: palette.primary.first
  },

  sliderContainer: {
    // Controls Styles secondaryButtonSize
    left: 45
  }
};

export default VolumeButton;
