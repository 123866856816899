import React from 'react';

const iconSearchNoResults = (
  <g fill="none" fillRule="evenodd" transform="translate(0 -16)">
    <path
      fill="#D0D0D0"
      d="M132 76c-13.252 0-24-10.748-24-24s10.748-24 24-24 24 10.748 24 24-10.748 24-24 24m56.244 20.928L162.452 71.14C165.948 65.588 168 59.04 168 52c0-19.884-16.116-36-36-36S96 32.116 96 52s16.116 36 36 36c7.044 0 13.588-2.052 19.14-5.548l25.788 25.792c.784.78 2.048.78 2.828 0l8.488-8.488c.78-.78.78-2.044 0-2.828"
    />
    <path
      fill="#D0D0D0"
      d="M145.184 37.728c-7.03-7.03-18.427-7.03-25.456 0-7.03 7.03-7.03 18.426 0 25.456 3.521 3.52 3.594-9.18 9.964-15.562 6.346-6.359 19-6.386 15.492-9.894z"
      opacity=".5"
    />
    <ellipse cx="144" cy="148" fill="#D0D0D0" opacity=".2" rx="144" ry="10" />
  </g>
);

export default iconSearchNoResults;
