import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const overlay = (props) => (
  <SvgIcon {...props} width={107} height={550} viewBox="0 0 107 550">
    <g
      id="vd_comp-header-category"
      transform="translate(-200.000000, -2044.000000)"
      fill="#FFFFFF"
    >
      <path
        d="M307,2044 C303.128241,2044 299.810557,2046.76522 299.118163,2050.56935 L201.924164,2584.56935 C201.838245,2585.0414 201.795025,2585.52021 201.795025,2586 C201.795025,2590.41828 205.381661,2594 209.806001,2594 L200,2594 L200,2044 L307,2044 Z"
        id="10-shape-header"
      />
    </g>
  </SvgIcon>
);

overlay.propTypes = SvgIcon.propTypes;

overlay.defaultProps = SvgIcon.defaultProps;

const SvgHeaderCurve = {
  overlay
};

export default SvgHeaderCurve;
