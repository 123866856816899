import { palette } from '../../palette/Palette';

const PlaylistLink = {
  // GENERAL STYLES
  link: {
    // hardcoded
    color: palette.default.black,
    // linkFontFace
    fontFamily: 'Radio10Regular',
    // linkLineHeight
    lineHeight: 1,
    // linkFontSize
    fontSize: 18,
    // linkFontStyle
    textTransform: 'none'
  },
  linkHover: {
    // linkHoverColor
    color: palette.secondary.first
  }
};

export default PlaylistLink;
