import { palette } from '../palette/Palette';

const HeaderSection = {
  root: {
    marginBottom: 10
  },

  imageWrapper: {
    margin: '0 auto',
    width: '88%',
    height: '100%'
  },

  imageWrapperSm: {
    margin: '0 20px'
  },

  link: {
    '& span': {
      color: palette.default.white
    }
  },

  contentWrapper: {
    width: '48%',
    margin: '-17.3% 0 0',
    float: 'right',
    padding: '38px 9% 30px 8.1%'
  },

  contentWrapperMd: {
    width: '60%'
  },

  contentWrapperSm: {
    width: '73%'
  },

  title: {
    margin: '19px 0 0',
    color: palette.default.black
  },

  titleMd: {
    margin: '11px 0 0'
  },

  titleSm: {
    margin: '18px 0 0'
  },

  highlight: {
    margin: '10px 0 20px',
    color: palette.grey.second
  },

  linkMd: {
    marginTop: 14,
    '& button': {
      minHeight: 42
    }
  },

  linkSm: {
    marginTop: 10,
    '& button': {
      display: 'flex',
      minHeight: 32
    }
  },

  shapeOuter: {
    width: '100%',
    left: '27.5%'
  },

  shapeWrapper: {
    position: 'relative',
    paddingTop: '21.5%'
  },

  shapeWrapperSm: {
    paddingTop: '15%'
  },

  shape: {
    margin: '-21% 0 0',
    left: 0,
    bottom: -2
  },

  shapeOuterMd: {
    width: '85%'
  },

  imageWrapperMd: {
    margin: '0 auto',
    width: '92.2%',
    maxWidth: 944
  },

  image: {
    position: 'relative'
  },

  svgLinkSm: {
    transform: 'translateY(-81%)'
  },

  secondButton: {
    '& span': {
      color: palette.default.white
    }
  },

  secondLink: {
    '& button': {
      backgroundColor: palette.grey.seventh,
      '&:hover': {
        backgroundColor: palette.primary.second
      }
    }
  },

  secondLinkMd: {
    '& button': {
      minHeight: 42
    }
  },

  secondLinkSm: {
    '& button': {
      display: 'flex',
      minHeight: 32
    }
  }
};

export default HeaderSection;
