import { palette } from '../palette/Palette';

const ArticleCard = {
  imageHolder: {
    paddingBottom: '46.7742%'
  },

  imageHolderMd: {
    paddingBottom: '64.3275%'
  },

  imageHolderSm: {
    paddingBottom: '40.2985%'
  },

  imageSize: {
    width: 620,
    height: 300
  },

  imageSizeMd: {
    width: 460,
    height: 300
  },

  imageSizeSm: {
    width: 730,
    height: 300
  },

  icon: {
    width: 32,
    height: 32,

    '& svg': {
      width: 32,
      height: 32
    }
  },

  textBackground: {
    border: `2px solid ${palette.grey.sixth}`
  },

  quoteIcon: {
    width: 40,
    height: 40
  },

  title: {
    marginBottom: 5
  },

  quoteBackground: {
    backgroundColor: palette.primary.first,
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  textCard: {
    color: palette.default.black,
    '&:hover': {
      color: palette.primary.first
    }
  },

  quoteTitle: {
    margin: '8px 0'
  },

  quoteAuthor: {
    opacity: 0.5
  },

  related: {
    paddingBottom: 30
  },

  imageCardTitle: {
    marginTop: 8
  },

  imageCardTitleSm: {
    marginTop: 4
  },

  imageCardTitleBig: {
    fontSize: 24
  },

  imageCardTitleBigMd: {
    fontSize: 19
  },

  textCardTitle: {
    marginTop: 8
  },

  textCardTitleSm: {
    marginTop: 4
  },

  textHolder: {
    centerTop: {
      top: 0,
      bottom: 'auto',
      textAlign: 'center',
      padding: '40px 50px'
    },

    centerBottom: {
      textAlign: 'center',
      padding: '20px 50px'
    },

    leftTop: {
      top: 0,
      bottom: 'auto'
    }
  },

  quoteBackgroundOverlay: {
    backgroundColor: palette.secondary.first
  },

  badgeHolder: {
    right: 0,
    rightTop: {
      top: 0
    },
    rightBottom: {
      bottom: 0
    }
  },
  imageTextCardHolder: {
    imageTextCard: {
      '&:after': {
        content: '""',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        top: 0,
        left: 0,
        zIndex: -1,
        borderRadius: 6,
        position: 'absolute'
      }
    },

    imageHolder: {
      paddingBottom: '100%',
      boxSizing: 'border-box',
      '-webkit-mask-image': '-webkit-radial-gradient(white, white)' // fix for safari browser for border radius not showing
    },

    left: {
      width: '47%',
      height: '100%'
    },

    right: {
      width: '53%',
      paddingLeft: 20,
      background: palette.gradient.second
    }
  }
};

export default ArticleCard;
