import { palette } from '../palette/Palette';

const ArticleSkeleton = {
  category: {
    backgroundColor: palette.primary.first
  },

  socialLink: {
    backgroundColor: palette.primary.first
  },

  lightGreyBox: {
    backgroundColor: palette.grey.sixth
  },

  darkGreyBox: {
    backgroundColor: palette.grey.third
  }
};

export default ArticleSkeleton;
