import { palette } from '../../palette/Palette';

const ColoredScrollBar = {
  wrapper: {
    // General Styles scrollBorderRadius
    borderRadius: 2.5,
    // General Styles scrollColor
    backgroundColor: palette.primary.first
  }
};

export default ColoredScrollBar;
