import { palette } from '../palette/Palette';

const UniversalVotingUserListTrack = {
  track: {
    backgroundColor: palette.grey.tenth,
    borderColor: palette.grey.first
  },

  votedTrack: {
    backgroundColor: palette.grey.first,
    borderColor: palette.grey.second
  },

  dragged: {
    borderColor: palette.primary.first
  },

  trackVoted: {
    borderColor: palette.grey.sixth
  },

  grabIcon: {
    '& svg': {
      '& g': {
        fill: palette.grey.second
      }
    }
  },

  removeIcon: {
    '& svg': {
      '& g': {
        fill: palette.grey.fifth
      }
    }
  },

  hightlightedNumber: {
    color: palette.grey.fifth
  },

  orderNumber: {
    color: palette.grey.fifth
  },

  artist: {
    color: palette.grey.fifth
  },

  title: {
    color: palette.grey.fifth
  },

  removeIconSvg: {
    width: 16,
    height: 16
  },

  trackBorderDragging: {
    borderLeftColor: palette.primary.first
  },

  dragDropIcon: {
    fill: palette.primary.first
  },

  mobileDragDropColor: {
    fill: palette.primary.first
  }
};

export default UniversalVotingUserListTrack;
