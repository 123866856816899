import { palette } from '../palette/Palette';

const ArticleComposite = {
  compositeImageHolder: {
    paddingBottom: '56.25%'
  },

  title: {
    color: palette.default.black
  },

  titleSm: {
    padding: '40px 20px 20px',
    marginTop: 50
  }
};

export default ArticleComposite;
