import { palette } from '../palette/Palette';

const FragmentJackpotSection = {
  wrapper: {
    '& $title': {
      color: palette.default.black
    }
  },

  title: {
    padding: '0 0 20px'
  },

  linkHolder: {
    padding: '0 75px 49px 0'
  },

  linkIcon: {
    padding: ' 0 0 0 14px',
    fill: palette.secondary.second
  },

  linkHolderMd: {
    padding: '0 40px 0 0'
  },

  linkHolderSm: {
    padding: '0 5px 0 0'
  }
};

export default FragmentJackpotSection;
