import { palette } from '../palette/Palette';

const IFrame = {
  iFrameWrapper: {
    '& iframe': {
      width: '100%'
    }
  },

  loader: {
    borderTop: `14px solid ${palette.primary.first}`
  }
};

export default IFrame;
