import { palette } from '../palette/Palette';

const SearchListItem = {
  itemHolder: {
    '&:after': {
      content: '" "',
      width: 'calc(100% - 32px)',
      height: 2,
      backgroundColor: palette.grey.fifth,
      position: 'absolute',
      bottom: 0
    }
  },

  itemHolderHover: {
    backgroundColor: palette.grey.fifth,

    '& $itemCategory': {
      color: palette.secondary.first
    }
  },

  itemHolderMd: {
    margin: '0 -15px',
    padding: '20px 15px'
  },

  highlight: {
    color: palette.primary.first
  },

  textHolder: {
    marginLeft: 12
  },

  imageHolder: {
    width: 114,
    height: 70
  },

  backgroundInner: {
    backgroundColor: palette.grey.third
  },

  articleText: {
    color: palette.grey.second
  },

  svgIcon: {
    width: 20,
    height: 20
  },

  videoIconSm: {
    width: 16,
    height: 16
  },

  divider: {
    color: palette.grey.second,
    height: 14
  },

  dividerMd: {
    height: 12
  },

  dividerSm: {
    height: 10
  },

  cardType: {
    display: 'none'
  },

  articleTitle: {
    color: palette.default.black
  },

  cardDate: {
    top: 0,
    color: palette.grey.second
  },

  itemCategory: {
    fontSize: 11,
    lineHeight: '11px',
    color: palette.primary.first
  },

  itemCategoryMd: {
    fontSize: 10,
    lineHeight: '10px'
  },

  itemCategorySm: {
    fontSize: 8,
    lineHeight: '9px'
  },

  articleTitleSm: {
    maxHeight: 30
  }
};
export default SearchListItem;
