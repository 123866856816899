const Newsletter = {
  button: {
    textTransform: 'lowercase',
    '& ::first-letter': {
      textTransform: 'uppercase'
    }
  },

  footerSubmitForm: {
    padding: '20px 0 0'
  }
};

export default Newsletter;
