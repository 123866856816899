import { palette } from '../palette/Palette';

const ArticleHeader = {
  iconHolder: {
    paddingRight: 4,
    height: 32,
    '& g': {
      fill: palette.grey.second
    }
  },

  iconCalendar: {
    width: 28,
    height: 28
  },

  dateTime: {
    color: palette.grey.second
  },

  categoryHolder: {
    paddingRight: 15
  },
  imageCaption: {
    color: palette.grey.eight
  },
  imageCaptionMd: {
    paddingLeft: 32
  }
};

export default ArticleHeader;
