import { palette } from '../palette/Palette';

const Button = {
  default: {
    minWidth: 112,
    minHeight: 52,
    border: 'none',
    padding: '16px 24px',
    backgroundColor: palette.secondary.second,
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: palette.secondary.second
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: palette.secondary.third
      }
    },
    '& *': {
      color: palette.default.white,
      fill: palette.default.white
    },

    borderRadius: 50
  },

  votingAddTrackBtn: {
    color: palette.default.white,
    backgroundColor: palette.secondary.second,
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: palette.secondary.second
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: palette.secondary.third
      }
    }
  },

  imageSectionButton: {
    fontSize: '1em',
    minWidth: 110,
    minHeight: 52,
    padding: '2px 20px 0 20px',
    boxSizing: 'border-box',
    textTransform: 'lowercase',
    backgroundColor: palette.secondary.second,
    borderRadius: 50,
    outline: 'none',
    border: 'none',
    objectFit: 'contain',
    '& *': {
      color: palette.default.white
    },
    '& ::first-letter': {
      textTransform: 'uppercase'
    },
    '&:hover': {
      backgroundColor: palette.secondary.third
    }
  },

  imageSectionButtonSl: {
    minWidth: 88,
    minHeight: 42
  },

  imageSectionButtonMd: {
    margin: '15px 0'
  },

  imageSectionButtonSm: {
    margin: 0,
    minWidth: 74,
    minHeight: 32
  },

  disabled: {
    opacity: 0.3
  },

  textFrameButton: {
    minHeight: 44,
    border: 'none',
    backgroundColor: palette.secondary.second,
    '&:hover': {
      backgroundColor: palette.secondary.third,
      cursor: 'pointer'
    },
    borderRadius: 50,
    color: palette.default.white
  },

  registerModalButton: {
    borderRadius: 50,
    backgroundColor: palette.grey.seventh,
    '& p': {
      color: palette.default.white
    },
    '&:hover': {
      backgroundColor: palette.primary.second
    }
  },

  defaultMd: {
    minWidth: 87,
    height: 42,
    padding: '12px 16px'
  },

  defaultSm: {
    minWidth: 73,
    height: 32,
    padding: '8px 12px'
  }
};

export default Button;
