import { palette } from '../palette/Palette';

const PageNotFound = {
  smallGrid: {
    width: '800px'
  },
  padding: 100,
  paddingLg: 150,
  paddingMd: 120,
  paddingSm: 70,
  logo: {
    width: 234,
    height: 192,
    marginBottom: 28
  },
  logoMd: {
    width: 185,
    height: 152
  },
  logoSm: {
    width: 171,
    height: 140
  },
  title: {
    color: palette.default.black
  },
  subTitle: {
    color: palette.default.black,
    marginTop: 16,
    marginBottom: 20
  },
  subTitleSm: {
    marginTop: 12,
    marginBottom: 17
  },
  featuredItemsTitle: {
    marginBottom: 20,
    color: palette.default.black
  },
  categoryTeaseHolder: {
    marginBottom: 32
  },
  categoryTeaseHolderMd: {
    marginBottom: 36
  }
};

export default PageNotFound;
