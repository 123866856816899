import { createContext, useContext, useEffect, useState } from 'react';

import { gigyaScreenSets } from '../../imports';
import config from '../../config';

const GigyaContext = createContext({
  isGigyaLoaded: false
});

export const useGigyaContext = () => useContext(GigyaContext);

type GigyaContextProviderProps = { children: any };

export const GigyaContextProvider = (props: GigyaContextProviderProps) => {
  const [isGigyaLoaded, setIsGigyaLoaded] = useState(false);
  let isMounted: boolean;

  const loadGigyaScript = async () => {
    isMounted = true;
    if (!gigyaScreenSets.gigyaScriptLoaded()) {
      const apiKey = config.getGigyaApiKey();

      try {
        const isLoaded = await gigyaScreenSets.loadGigyaScript(apiKey);
        isMounted && setIsGigyaLoaded(isLoaded);
      } catch (err: any) {
        console.warn(err.message);
        isMounted && setIsGigyaLoaded(false);
      }
    }
  };

  useEffect(() => {
    loadGigyaScript();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <GigyaContext.Provider value={{ isGigyaLoaded }}>
      {props.children}
    </GigyaContext.Provider>
  );
};
