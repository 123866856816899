import React from 'react';

const mobileDragUp = (
  <g id="assets" stroke="none" strokeWidth="1">
    <g
      id="assets/dragdrop_icon_up"
      transform="translate(-14.000000, -21.000000)"
    >
      <path
        d="M14.2564101,38.8370307 L17.9731274,42.3885578 C18.3151108,42.7155512 18.869466,42.7155512 19.2114494,42.3885578 L31.4072565,30.7221264 C31.5712568,30.5646846 31.7940549,30.4761855 32.0264175,30.4761855 C32.2587801,30.4761855 32.4815782,30.5646846 32.6455785,30.7221264 L44.8466402,42.3885578 C45.1886236,42.7155512 45.7429787,42.7155512 46.0849621,42.3885578 L49.7999279,38.8370307 C50.1418081,38.5099385 50.1418081,37.9797221 49.7999279,37.6526299 L36.984084,25.3914854 L32.64733,21.245245 C32.3053466,20.9182517 31.7509914,20.9182517 31.409008,21.245245 L27.075757,25.3931606 L14.2564101,37.6526299 C13.91453,37.9797221 13.91453,38.5099385 14.2564101,38.8370307"
        id="Combined-Shape"
      />
    </g>
  </g>
);

export default mobileDragUp;
