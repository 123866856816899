import { palette } from '../palette/Palette';
import bg1 from '../../assets/programGuide/background-1.svg';
import bg2 from '../../assets/programGuide/background-2.svg';
import bg3 from '../../assets/programGuide/background-3.svg';
import bg4 from '../../assets/programGuide/background-4.svg';
import bg5 from '../../assets/programGuide/background-5.svg';

const ProgramSlide = {
  containerOuter: {
    paddingRight: 10
  },
  containerInner: {
    cursor: 'default',
    backgroundSize: 'cover'
  },
  containerOuterActive: {
    paddingRight: 10,
    containerInner: {
      transition: 'background-color 0.15s ease-in-out'
    }
  },
  showName: {
    color: palette.default.white
  },
  showNameLine: {
    background: palette.default.black,
    borderRadius: '50px',
    padding: '4px 12px'
  },
  showNameLineSm: {
    padding: '4px 8px'
  },
  graphicWrapper: {
    height: 63
  },
  onAirBadge: {
    padding: '5px 8px',
    width: '50px',
    heigth: '14px',
    textTransform: 'lowercase',
    '& ::first-letter': {
      textTransform: 'uppercase'
    }
  },
  onAirBadgeMd: {
    heigth: '13px'
  },
  timeStart: {
    left: 16,
    color: palette.default.white,
    bottom: 16
  },
  timeEnd: {
    right: 16,
    color: palette.default.white,
    bottom: 16
  },
  timeStartMd: {
    left: 8,
    bottom: 8
  },
  timeEndMd: {
    right: 8,
    bottom: 8
  },
  daySliderBackground: {
    width: 240,
    backgroundColor: palette.default.black,
    backgroundImage: 'none'
  },
  daySliderBackgroundMd: {
    width: 200
  },
  daySliderBackgroundSm: {
    width: 172
  },

  slide1: {
    backgroundImage: `url(${(bg1 as any).src})`
  },
  slide2: {
    backgroundImage: `url(${(bg2 as any).src})`
  },
  slide3: {
    backgroundImage: `url(${(bg3 as any).src})`
  },
  slide4: {
    backgroundImage: `url(${(bg4 as any).src})`
  },
  slide5: {
    backgroundImage: `url(${(bg5 as any).src})`
  }
};

export default ProgramSlide;
