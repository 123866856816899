import { palette } from '../palette/Palette';

const Modal = {
  modalHeader: {
    backgroundImage: palette.gradient.second,
    color: palette.default.white
  },

  modalButton: {
    backgroundColor: palette.grey.seventh,
    '&:hover': {
      backgroundColor: palette.default.black
    }
  },

  closeIconSvg: {
    '& g': {
      fill: palette.default.white
    }
  },

  firstButtonMd: {
    minHeight: 39,
    height: 39
  },

  firstButtonSm: {
    minHeight: 30,
    height: 30
  },

  modalButtonMd: {
    minHeight: 39,
    height: 39
  },

  modalButtonSm: {
    minHeight: 30,
    height: 30
  }
};

export default Modal;
