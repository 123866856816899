import { palette } from '../palette/Palette';

const UniversalVotingPopup = {
  popupInner: {
    background: palette.default.white
  },

  buttonWrapper: {
    borderTop: `1px solid ${palette.grey.sixth}`
  },

  button: {
    color: palette.primary.first,
    cursor: 'pointer'
  },

  alreadyVotedBtnOk: {
    fontFamily: 'PoppinsBold',
    fontSize: 19,
    color: palette.primary.first,
    cursor: 'pointer'
  },

  horizontalLine: {
    borderBottomColor: palette.grey.fourth
  },

  buttonMaxVotedSm: {
    width: 177,
    height: 40
  }
};

export default UniversalVotingPopup;
