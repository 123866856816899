import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconMessage = (
  <path
    d="M61.538 5a2.465 2.465 0 0 1 2.425 2.89l-6.944 39.384a2.462 2.462 0 0 1-2.425 2.034H28.416L27.04 57.12a2.462 2.462 0 0 1-4.15 1.326l-9.307-9.14H2.462A2.462 2.462 0 0 1 0 46.848V7.461A2.462 2.462 0 0 1 2.462 5zM48.172 24.692H12.308a2.462 2.462 0 0 0 0 4.923h35.864a2.462 2.462 0 0 0 0-4.923zm1.723-9.846H12.308a2.462 2.462 0 0 0 0 4.923h37.587a2.462 2.462 0 0 0 0-4.923z"
    fill={palette.grey.seventh}
  />
);

export default iconMessage;
