import { palette } from '../palette/Palette';

const TextFrameStyles = {
  textFrame: {
    backgroundColor: palette.grey.fifth,
    padding: '42px 40px 38px',
    marginRight: 0
  },

  textFrameMd: {
    padding: 40
  },

  textFrameSm: {
    maxWidth: 375,
    padding: '40px 20px'
  },

  title: {
    paddingBottom: 20,
    color: palette.default.black
  },
  button: {
    padding: 16
  },
  buttonMd: {
    padding: '15px 16px'
  },
  buttonSm: {
    padding: '14px 18px'
  }
};
export default TextFrameStyles;
