const LoginButton = {
  // This were hardcoded values in props in Login popup component on DSC, since it is used only in Veronica this is just a placeholder
  login: {
    backgroundColor: '#df3232'
  },

  register: {
    backgroundColor: '0091ff'
  }
};

export default LoginButton;
