import { palette } from '../../palette/Palette';

const Controls = {
  hover: {
    fill: palette.primary.first
  },
  hoverColor: {
    color: '#87b919'
  },
  chevronIcon: {
    fill: palette.grey.seventh
  }
};

export default Controls;
