import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconMiniPlayerChevron = (
  <>
    <defs>
      <circle id="o1p0g3sb2a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-0.000000, -0.000000) translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000)">
            <circle cx="32" cy="32" r="32" />
            <circle cx="32" cy="32" r="32" fill={palette.grey.sixth} />
          </g>
          <g fill={palette.grey.first}>
            <path
              d="M20.626 4.688v20.219c0 .434-.263.778-.697.778h-3.046c-.434 0-.697-.344-.697-.778V9.097H.352c-.436 0-.696-.259-.696-.779V5.466c0-.432.26-.779.696-.779h15.834l4.437.003.003-.002z"
              transform="translate(-0.000000, -0.000000) translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000) translate(17.000000, 17.000000) translate(10.140766, 15.186328) rotate(45.000000) translate(-10.140766, -15.186328)"
            />
          </g>
        </g>
      </g>
    </g>
  </>
);

export default iconMiniPlayerChevron;
