const JWPlayer = {
  wrapper: {
    // Cover Styles tileSize[0]
    width: 276,
    height: 276
  },

  wrapperSm: {
    // Cover Styles tileSize[1]
    width: 255,
    height: 255
  }
};

export default JWPlayer;
