import { palette } from '@theme/palette/Palette';

const AudioVideoToggle = {
  toggleButton: {
    // Media Switcher Styles fontFace
    fontFamily: 'PoppinsBold',

    // Media Switcher fontSize
    fontSize: 16,

    // Media Switcher fontStyle
    textTransform: 'none',

    // Media Switcher textColor
    color: palette.secondary.second,

    // Media Switcher hoverColor
    hoverColor: palette.secondary.third
  }
};

export default AudioVideoToggle;
