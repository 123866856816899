import { palette } from '../palette/Palette';

const SingleClip = {
  iconSize: 40,
  iconSizeMd: 32,
  iconSizeSm: 32,
  imageSize: 112,
  imageSizeSm: 56,

  audioHolderInner: {
    backgroundColor: palette.grey.fifth,
    '& $imageHolder': {
      '& $image': {
        borderRadius: 4
      }
    },
    '& $optionsHolder': {
      '& svg': {
        '& circle': {
          fill: palette.secondary.second
        }
      }
    },
    '& $textHolder': {
      color: palette.default.black
    }
  }
};

export default SingleClip;
