import { generateTheme } from '@talpanetwork/talpa-publishing-theme/theming/generateTheme';

import * as iconSet from '../icons/index';

import * as styles from './styles';
import { palette } from './palette/Palette';

const fontSize = 16;

const fontFallback = 'sans-serif';

const Radio10Regular = `'Radio10Regular', ${fontFallback}`;
const PoppinsRegular = `'PoppinsRegular', ${fontFallback}`;
const PoppinsBold = `'PoppinsBold', ${fontFallback}`;

export function pxToEm(value) {
  return `${value / fontSize}em`;
}

const defaultTheme = generateTheme();

export const styleOptions = generateTheme({
  palette: {
    ...palette
  },

  icons: {
    ...iconSet,
    defaultFill: palette.primary.first
  },

  shape: {
    noBorderRadius: 0,
    borderRadius: 4
  },

  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },

  components: {
    ...styles
  },

  spacing: {
    unit: 10
  },

  typography: {
    fontFamilyHeavy: Radio10Regular,
    fontFamilyBold: PoppinsBold,
    fontFamilyRegular: PoppinsRegular,
    fontFamilyBoldAlt1: Radio10Regular,
    fontFamilyHeavyAlt1: Radio10Regular,

    campaignHeader: {},

    headerLarge: {
      fontSize: pxToEm(21),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      }
    },

    headerMedium: {
      fontFamily: Radio10Regular,
      fontSize: pxToEm(21),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      }
    },

    frequencySearchClearText: {
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '16px'
      }
    },

    headerSmall: {
      fontFamily: Radio10Regular,
      fontSize: pxToEm(16),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      }
    },

    articleHeadline: {
      fontSize: pxToEm(31),
      lineHeight: '38px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(26),
        lineHeight: '32px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      }
    },

    notFoundTitle: {
      fontSize: pxToEm(21),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      }
    },

    headlineLarge: {
      fontSize: pxToEm(24),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(19),
        lineHeight: '24px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16),
        lineHeight: '20px'
      }
    },

    loginProfileName: {
      fontSize: pxToEm(19),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '16px'
      }
    },

    headlineMedium: {
      fontSize: pxToEm(16),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      }
    },

    headlineMediumRelatedArticle: {
      fontSize: pxToEm(16),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      }
    },

    correctGuesses: {},

    headlineSmall: {
      fontSize: pxToEm(13),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(11),
        lineHeight: '12px'
      }
    },
    buttonCta: {
      fontSize: pxToEm(17),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(15),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '16px'
      }
    },

    titleMedium: {
      fontSize: pxToEm(19),
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '20px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '18px'
      }
    },

    videoListTitle: {
      fontSize: pxToEm(21),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      }
    },

    signatureHeadlineLarge: {
      fontSize: pxToEm(30),
      lineHeight: '38px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(25),
        lineHeight: '32px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(19),
        lineHeight: '24px'
      }
    },

    signatureInitial: {
      fontSize: pxToEm(34),
      lineHeight: '38px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(28),
        lineHeight: '32px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(21),
        lineHeight: '24px'
      }
    },

    signatureHeadlineMedium: {
      fontSize: pxToEm(19),
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '20px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      }
    },

    tag: {
      color: palette.default.white,
      fontSize: pxToEm(11),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(8),
        lineHeight: '10px'
      }
    },

    infoMedium: {
      fontSize: pxToEm(20),
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '20px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '18px'
      }
    },

    paragraph: {
      fontSize: pxToEm(17),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(15),
        lineHeight: '28px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '24px'
      }
    },

    paragraphBold: {
      fontSize: pxToEm(17),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(15),
        lineHeight: '28px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '24px'
      }
    },

    infoSmall: {
      fontSize: pxToEm(13),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(11),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(9),
        lineHeight: '10px'
      }
    },

    inputMedium: {
      fontSize: pxToEm(25),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(17),
        lineHeight: '20px'
      }
    },

    inputSmall: {
      fontSize: pxToEm(16),
      lineHeight: '18px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '16px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      }
    },

    caption: {
      fontSize: pxToEm(14),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '16px'
      }
    },

    labelMedium: {
      fontSize: pxToEm(14),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(11),
        lineHeight: '12px'
      }
    },

    labelSmall: {
      fontSize: pxToEm(12),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(8),
        lineHeight: '10px'
      }
    },

    link: {
      fontSize: pxToEm(19),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '16px'
      }
    },

    linkSmall: {
      fontSize: pxToEm(13),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(11),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(9),
        lineHeight: '10px'
      }
    },

    trackRegular: {
      fontSize: pxToEm(14),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      }
    },

    trackBold: {
      fontFamily: PoppinsBold,
      fontSize: pxToEm(15),
      lineHeight: '18px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      }
    },

    guessIndex: {},

    contactItem: {
      fontFamily: Radio10Regular,
      fontSize: pxToEm(16),
      lineHeight: '18px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '16px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      }
    },

    date: {
      fontSize: pxToEm(14),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      }
    },

    dateClip: {
      fontFamily: Radio10Regular,
      fontSize: pxToEm(16),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      }
    },

    quote: {
      fontSize: pxToEm(24),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16),
        lineHeight: '20px'
      }
    },

    stationTitle: {
      fontFamily: Radio10Regular,
      fontSize: pxToEm(16),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('ml')]: {
        fontSize: pxToEm(12),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(9),
        lineHeight: '12px'
      }
    }
  },

  utils: {
    grid: {
      width: {
        default: '83.71%',
        large: '88%',
        medium: '92.2%',
        small: '100%'
      },

      maxWidth: {
        default: '1280px',
        large: '1067px',
        medium: '944px',
        small: '100%'
      }
    },

    smallGrid: {
      width: 832
    }
  }
});

export const generateThemeWithDeviceType = (deviceType) => {
  return generateTheme(styleOptions, deviceType);
};

export const theme = generateTheme(styleOptions);

export default theme;
