import React from 'react';

const iconClose = (
  <g xmlns="http://www.w3.org/2000/svg" fill="#000" fillRule="evenodd">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M71.4358333,27.1970666 C72.3888487,27.2001451 72.9600333,27.9602957 72.9630787,28.9030637 L72.9832389,35.1386543 C72.986879,36.2710007 72.4199811,36.8378986 71.4669656,36.8348201 L36.8509834,36.7230017 L36.9626363,71.2877465 C36.9656983,72.2356383 36.3942725,72.9869743 35.4463807,72.9839217 L28.7855197,72.9623961 C27.8376279,72.9593341 27.2613362,72.2042907 27.2582742,71.256399 L27.1466213,36.6916542 L-7.4693609,36.5798357 C-8.4223764,36.5767573 -8.99293206,36.0113088 -8.99660638,34.8738387 L-9.01676114,28.638248 C-9.0197943,27.69548 -8.45350879,26.9390038 -7.50049329,26.9420823 L27.1154889,27.0539007 L27.0044319,-7.32638955 C27.0007907,-8.45361218 27.5728123,-9.02049352 28.520704,-9.01744393 L35.181565,-8.99591532 C36.1294568,-8.99285339 36.7051362,-8.42738836 36.7088105,-7.28991825 L36.8198676,27.090372 L71.4358333,27.1970666 Z"
      id="Path"
      fill="#FFFFFF"
      transform="translate(31.9832, 31.9832) rotate(45) translate(-31.9832, -31.9832)"
    />
  </g>
);

export default iconClose;
