import { palette } from '../palette/Palette';

const List = {
  root: {
    padding: '25px'
  },

  refHolder: {
    display: 'block',
    boxSizing: 'border-box',
    textAlign: 'center',
    '& $refTitle': {
      color: palette.default.white
    }
  },

  articleListTitle: {
    color: palette.grey.eight,
    marginBottom: 20
  },

  titleTextWrapper: {
    padding: '0 20px'
  },

  listMasonryTitle: {
    backgroundColor: palette.primary.first,
    textAlign: 'center'
  },

  listSquareTitle: {
    height: 60,
    lineHeight: '60px'
  },

  listSquareTitleLl: {
    height: 50,
    lineHeight: '50px'
  },

  listSquareTitleMd: {
    height: 40,
    lineHeight: '40px'
  },

  listSquareTitleSm: {
    height: 60,
    lineHeight: '60px',
    textAlign: 'left'
  },

  listLongTitle: {
    height: 80,
    lineHeight: '80px'
  },

  listLongTitleMd: {
    height: 60,
    lineHeight: '60px'
  },

  listLongTitleSm: {
    height: 60,
    lineHeight: '60px',
    textAlign: 'left'
  },

  listLongItemHolder: {
    transform: 'translate(0,-15px)'
  }
};

export default List;
