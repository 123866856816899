import { ReactElement, useState, useEffect, useCallback } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import dynamic from 'next/dynamic';
import Script from 'next/script';

import '../styles/globals.css';
import '../public/assets/css/packages.css';
import tealium from '@services/Tealium.service';
import { ApplicationModel } from '@type/index';

import { generateThemeWithDeviceType } from '@theme/Theme.styles';

import BrandHead from '@components/BrandHead/BrandHead';

import { Player } from '@imports';

import { LayoutProps } from '@components/Layout/Layout';

import { OptanonWrapper } from '@utils/optanonWrapper';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import {
  useGrowthBookHook,
  initGrowthBook
} from '@talpanetwork/talpa-publishing-core/GrowthBook/useGrowthbook';
import { growthBookConfig } from '@services/GrowthBook.service';

import {
  JssThemeProvider,
  useStore,
  ContentContextProvider,
  BrowserHistoryContextProvider,
  Meta,
  setApplicationDataFromJson,
  getApplicationLandingPageData
} from '../src/imports';

import applicationData from '../application_data.json';

import { GigyaContextProvider } from '../src/contexts/gigya/gigyaContext';

import AuthInterceptor from './auth-intercept';

const Layout: React.ComponentType<LayoutProps> = dynamic(
  () => {
    return import('@components/Layout/Layout');
  },
  { ssr: true }
);

const growthbook = initGrowthBook(growthBookConfig);

const MyApp = ({ Component, pageProps }: AppProps): ReactElement | null => {
  const application = applicationData.data.application as any;
  const applicationModel: ApplicationModel =
    getApplicationLandingPageData(application);
  setApplicationDataFromJson(application);

  const store = useStore({ ...{ landing: applicationModel } });
  const theme = generateThemeWithDeviceType(pageProps.deviceType);
  const [key, setKey] = useState(0);
  const tealiumEventHandler = useCallback(tealium.tealiumClickHandler, [
    tealium.tealiumClickHandler
  ]);

  const [pathName, setPathName] = useState('');

  const registerServiceWorker = () => {
    navigator.serviceWorker.register('/sw-pwa.js').then(
      (registration) => {
        // eslint-disable-next-line no-console
        console.log(
          'Service Worker registration successful with scope: ',
          registration.scope
        );
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log('Service Worker registration failed: ', err);
      }
    );
  };

  useEffect(() => {
    // tmp solution to trigger rehydration
    // due to css missmatch
    setKey(1);

    setPathName(window.location.pathname);

    const ssStyles = document.getElementById('server-side-styles');
    if (ssStyles) {
      ssStyles.remove();
    }

    if ('serviceWorker' in navigator) {
      registerServiceWorker();
    }

    document.addEventListener('click', tealiumEventHandler);

    return function cleanup() {
      document.removeEventListener('click', tealiumEventHandler);
    };
  }, []);

  useGrowthBookHook(growthbook);

  return (
    <ContentContextProvider>
      <BrowserHistoryContextProvider>
        <Provider store={store}>
          <JssThemeProvider theme={{ ...theme }} key={key}>
            <Script>{OptanonWrapper}</Script>
            <Head>
              {pageProps.metadata && (
                <Meta
                  metadata={pageProps.metadata}
                  schemaData={pageProps.schemaData}
                  queryParams={pageProps.queryParams}
                  imageLambdaBase={pageProps.imageLambdaBase}
                />
              )}
              <BrandHead />
            </Head>
            <GigyaContextProvider>
              <GrowthBookProvider growthbook={growthbook}>
                {pathName === '/player' ? (
                  <Player standalone />
                ) : pathName === '/auth-intercept' ? (
                  <AuthInterceptor />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </GrowthBookProvider>
            </GigyaContextProvider>
          </JssThemeProvider>
        </Provider>
      </BrowserHistoryContextProvider>
    </ContentContextProvider>
  );
};
export default MyApp;
