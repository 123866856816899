import React from 'react';

const mobileDragDown = (
  <g id="assets" stroke="none" strokeWidth="1">
    <g
      id="assets/dragdrop_icon_down"
      transform="translate(-14.000000, -21.000000)"
    >
      <path
        d="M49.7999279,24.7967721 L46.0832106,21.245245 C45.7412272,20.9182517 45.1868721,20.9182517 44.8448887,21.245245 L32.6490815,32.9116764 C32.4850813,33.0691183 32.2622832,33.1576174 32.0299205,33.1576174 C31.7975579,33.1576174 31.5747598,33.0691183 31.4107596,32.9116764 L19.2096979,21.245245 C18.8677145,20.9182517 18.3133593,20.9182517 17.9713759,21.245245 L14.2564101,24.7967721 C13.91453,25.1238643 13.91453,25.6540807 14.2564101,25.9811729 L27.072254,38.2423175 L31.409008,42.3885578 C31.7509914,42.7155512 32.3053466,42.7155512 32.64733,42.3885578 L36.980581,38.2406422 L49.7999279,25.9811729 C50.1418081,25.6540807 50.1418081,25.1238643 49.7999279,24.7967721"
        id="Combined-Shape"
      />
    </g>
  </g>
);

export default mobileDragDown;
