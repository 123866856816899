import { palette } from '../palette/Palette';

const SocialShare = {
  socialShareInnerBackground: {
    backgroundColor: palette.primary.first,

    '& $shareIcon': {
      fill: palette.default.white,
      width: 18,
      height: 18
    },

    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  socialShare: {
    marginTop: 32
  },

  socialShareMd: {
    marginTop: 24
  },

  socialShareSm: {
    marginTop: 20
  }
};

export default SocialShare;
