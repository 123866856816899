import { palette } from '../palette/Palette';

const SocialEmbed = {
  loader: {
    borderTop: `14px solid ${palette.primary.first}`
  },
  tweeterWrapper: {
    '& > div': {
      display: 'block'
    }
  }
};

export default SocialEmbed;
