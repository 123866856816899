import { palette } from '../../palette/Palette';

const Tab = {
  link: {
    // Tabbed Navigation Styles textColor
    color: palette.primary.second,
    // Tabbed Navigation Styles fontFace
    fontFamily: 'Radio10Regular',
    // Tabbed Navigation Styles fontSize
    fontSize: 20,
    // Tabbed Navigation Styles lineHeight
    lineHeight: 1.25,
    // general styles  gutter / 2
    padding: 15,
    // Tabbed Navigation Styles fontStyle
    textTransform: 'none'
  },
  linkHover: {
    // Tabbed Navigation Styles hoverColor
    color: palette.secondary.second,
    // Tabbed Navigation Styles hoverColor
    borderBottomColor: palette.secondary.second
  },
  linkSelected: {
    // Tabbed Navigation Styles activeColor
    color: palette.secondary.second,
    // Tabbed Navigation Styles activeColor
    borderBottomColor: palette.secondary.second
  }
};

export default Tab;
