import { palette } from '../../palette/Palette';

const Playlist = {
  wrapper: {
    // General Styles backgroundColor
    backgroundColor: palette.default.white
  },

  mainPlayer: {
    // General Styles backgroundColor
    backgroundColor: palette.grey.fifth
  },

  title: {
    // General Styles headingColor
    color: palette.grey.second,
    // General Styles headingFontFace
    fontFamily: 'Radio10Regular',
    // General Styles headingFontSize
    fontSize: 17,
    // General Styles headingLineHeight
    lineHeight: 1.65,
    // General Styles headingFontStyle
    textTransform: 'titlecase'
  },

  link: {
    // General Styles linkColor (THIS COLOR IS WRONG IN CONTENTFUL RESULTING IN BLACK LINK COLOR)
    color: palette.default.black,
    // General Styles linkFontFace
    fontFamily: 'Radio10Regular',
    // General Styles linkFontSize
    fontSize: 18,
    // General Styles linkHoverColor
    textTransform: 'none'
  },

  linkHover: {
    // General Styles linkFontStyle
    color: palette.secondary.first
  },

  bar: {
    // Media Switcher Styles backgroundColor
    background: '#ececec',
    // General Styles gutter
    marginLeft: -30,
    marginRight: -30,
    paddingLeft: 30,
    paddingRight: 30
  }
};

export default Playlist;
