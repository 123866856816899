import React from 'react';

import { palette } from '../theme/palette/Palette';

const dragDrop = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <path
      d="M44.845 42.611a.904.904 0 0 1 1.238 0l3.717 3.552a.812.812 0 0 1 0 1.184l-12.82 12.26-4.333 4.148a.904.904 0 0 1-1.238 0l-4.337-4.146-12.816-12.262a.812.812 0 0 1 0-1.184l3.715-3.552a.904.904 0 0 1 1.239 0l12.2 11.667a.895.895 0 0 0 .62.246.895.895 0 0 0 .62-.246zM31.409.245a.904.904 0 0 1 1.238 0l4.337 4.146L49.8 16.653a.812.812 0 0 1 0 1.184l-3.715 3.552a.904.904 0 0 1-1.238 0L32.646 9.722a.895.895 0 0 0-.62-.246.895.895 0 0 0-.619.246L19.211 21.39a.904.904 0 0 1-1.238 0l-3.717-3.552a.812.812 0 0 1 0-1.184l12.82-12.26z"
      fill={palette.primary.first}
    />
  </g>
);

export default dragDrop;
