import { palette } from '../palette/Palette';

const UniversalVotingSearchInput = {
  searchInput: {
    '&:focus': {
      border: `2px solid ${palette.primary.first}`
    }
  }
};

export default UniversalVotingSearchInput;
