import { palette } from '../palette/Palette';

const UniversalVotingMotivation = {
  clearButton: {
    color: palette.primary.first
  },

  saveButton: {
    color: palette.primary.first
  },

  textInfoIcon: {
    '& svg': {
      fill: palette.default.black
    }
  }
};
export default UniversalVotingMotivation;
