import { palette } from '../../palette/Palette';
import playerTileBackground from '../../../assets/playerTileBackground.png';

const MiniPlayer = {
  title: {
    // Mobile Player text1FontFace
    fontFamily: 'UniNeueHeavy',
    // Mobile Player text1FontSize
    fontSize: 12
  },

  subTitle: {
    // Mobile Player Styles text2Color
    color: palette.grey.first,
    // Mobile Player text2FontFace
    fontFamily: 'UniNeueBold',
    // Mobile Player text2FontSize
    fontSize: 10
  },

  tileSm: {
    backgroundSize: 40,
    backgroundImage: `url(${playerTileBackground})`
  },
  category: {
    backgroundColor: palette.primary.first
  },
  rankingPosition: {
    backgroundColor: palette.primary.first
  }
};

export default MiniPlayer;
