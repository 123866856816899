import { palette } from '../palette/Palette';

const MenuItem = {
  itemArrowIcon: {
    width: 20,
    height: 20,
    transition: 'all 0.3s ease'
  },
  menuItem: {
    padding: '16px 30px',
    '&$firstMenuItem': {
      '&:hover': {
        backgroundColor: palette.primary.first,
        transitionDelay: '0.2s',

        '& $textHolder': {
          color: palette.default.white,
          transition: 'all 0.3s ease 0.2s'
        },

        '& $itemArrowIcon': {
          fill: palette.default.white,
          transition: 'all 0.3s ease 0.2s'
        }
      }
    },

    '&:hover': {
      backgroundColor: palette.default.white
    }
  },
  activeElement: {
    backgroundColor: palette.primary.first,

    '& $textHolder': {
      color: palette.default.white
    },
    '& $itemArrowIcon': {
      fill: palette.default.white
    },
    '&:hover': {
      transition: 'all 0.3s ease 0.2s',
      '& $itemArrowIcon': {
        fill: palette.primary.first
      },
      '& $textHolder': {
        color: palette.primary.first
      }
    }
  },
  noSubitems: {
    '& $textHolder': {
      color: palette.default.black
    },
    '& $itemArrowIcon': {
      fill: palette.default.black
    },
    '&:hover': {
      '& $textHolder': {
        color: palette.default.black
      }
    }
  },
  menuItemMd: {
    paddingLeft: 20,
    paddingRight: 20
  }
};

export default MenuItem;
