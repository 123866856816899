import { palette } from '../palette/Palette';

const UniversalVotingEndScreen = {
  lineSeparator: {
    borderBottom: `2px solid ${palette.grey.fifth}`
  },

  bodyText: {
    link: {
      color: palette.secondary.first
    },

    subscript: {
      color: palette.grey.third
    },

    color: palette.default.black
  }
};

export default UniversalVotingEndScreen;
