import { palette } from '../palette/Palette';

const StationCard = {
  singleStation: {
    padding: '0 10px',
    margin: '0 0 14px',
    width: '20%',
    '&:nth-child(5n+1)': {
      clear: 'both'
    }
  },

  overlay: {
    textAlign: 'center',
    width: '100%',
    bottom: '10%'
  },

  logoHolder: {
    top: '42%',
    zIndex: 0,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'wrap'
  },

  singleMobileStation: {
    padding: '0 8px'
  },

  imageOverlay: {
    backgroundColor: palette.primary.first
  },

  imageOverlayHover: {
    opacity: 0.8
  },

  playIcon: {
    '& circle': {
      fill: palette.secondary.second
    }
  },

  playIconHover: {
    '& circle': {
      fill: palette.secondary.third
    }
  },

  title: {
    textAlign: 'center',
    padding: '6px 12px'
  },

  textHolder: {
    backgroundColor: palette.default.black,
    borderRadius: 50
  },

  titleMd: {
    padding: '2px 6px'
  }
};

export default StationCard;
