import { palette } from '../palette/Palette';

const LinkListAudioEmbed = {
  notPlaying: {
    width: 64,
    paddingRight: 20
  },

  notPlayingMd: {
    width: 48
  },

  notPlayingSm: {
    width: 48,
    paddingRight: 0
  },

  playing: {
    width: '124px',
    paddingRight: '32px'
  },

  title: {
    '&:hover': {
      color: palette.primary.first
    },
    color: palette.default.black
  },

  imageOverlay: {
    backgroundColor: palette.primary.first
  },

  playingMd: {
    width: '88px',
    paddingRight: '21px'
  },

  playingSm: {
    width: '74px',
    paddingRight: '20px'
  },

  audioHolderInner: {
    maxHeight: 112,

    '&:hover $textHolder': {
      color: palette.primary.first
    },

    '&:hover': {
      backgroundColor: palette.grey.fifth
    }
  },

  iconStop: {
    top: 'auto',
    display: 'block',
    position: 'relative',
    transform: 'initial'
  },

  imageHolder: {
    width: '27%'
  },

  imageHolderSm: {
    width: 56,
    maxHeight: 56
  },

  image: {
    maxWidth: '100%',
    height: 112
  },

  iconPlay: {
    height: 64,
    width: 64
  },

  iconPlayMd: {
    height: 40,
    width: 40
  },

  iconPlaySm: {
    height: 40,
    width: 40
  },

  textHolderInnerSm: {
    left: '10px'
  },

  textHolder: {
    color: palette.default.black,
    width: '72%',
    padding: '0 40px'
  },

  squareImageHolderSm: {
    width: 56
  },

  audioHolder: {
    paddingTop: 0,
    paddingBottom: 10
  },

  audioEmbed: {
    backgroundColor: palette.grey.fifth,
    '&:hover': {
      backgroundColor: palette.grey.sixth
    },
    '& $title': {
      '&:hover': {
        color: palette.default.black
      }
    }
  },

  imageHolderAudioEmbed: {
    width: 200
  },

  square: {
    '& $image': {
      maxHeight: 66,
      height: 'auto'
    },
    '& $imageHolder': {
      width: '35%'
    },
    '& $textHolder': {
      padding: '0 15px 0 15px'
    },
    '&$audioHolder': {
      paddingTop: 10,
      paddingBottom: 0
    },
    '& $iconPlay': {
      width: 110,
      height: 36
    },
    '& $iconStop': {
      width: 110,
      height: 36
    }
  },

  squareMd: {
    '& $iconPlay': {
      width: 32,
      paddingLeft: 20
    },
    '& $iconStop': {
      width: 32,
      paddingLeft: 20
    }
  },

  long: {
    '& $image': {
      height: 'auto'
    },
    '& $imageHolder': {
      width: '45%'
    },
    '& $textHolder': {
      padding: '0 15px 0 15px'
    },
    '&$audioHolder': {
      paddingTop: 10,
      paddingBottom: 0
    },
    '& $iconPlay': {
      width: 110,
      height: 36
    }
  },

  squareImageSm: {
    height: 'auto'
  },

  squareNotPlayingSm: {
    width: 80
  },

  longNotPlayingSm: {
    width: 80
  },

  articleImageSm: {
    height: 63
  },

  articleImageHolderAudioEmbedSm: {
    width: 100
  },

  articleTextHolderSm: {
    padding: '0 15px'
  },

  longImageHolderMl: {
    width: '37%'
  },

  longImageHolderMd: {
    width: '45%'
  }
};

export default LinkListAudioEmbed;
