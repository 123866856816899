import { palette } from '../../palette/Palette';

const TileMessage = {
  messageBox: {
    // hardcoded level of grey  #f6f6f6
    backgroundColor: palette.grey.fifth,
    // hardcoded #3c3c3b
    color: palette.primary.second
  },
  title: {
    // cover styles text1FontFace
    fontFamily: 'Radio10Regular',
    // cover styles text1Color
    color: palette.primary.second
  },
  text: {
    // cover styles text2Color
    color: palette.grey.first,
    // cover styles text2FontFace
    fontFamily: 'PoppinsBold',
    // cover text2FontSize
    fontSize: 16
  },
  overlay: {
    // hardcoded white
    backgroundColor: palette.default.white
  }
};

export default TileMessage;
