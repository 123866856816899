import { palette } from '../palette/Palette';

const HeaderSectionSlide = {
  contentWrapper: {
    width: '100%',
    maxWidth: 'none'
  },
  contentWrapperLg: {
    width: '100%',
    maxWidth: 'none'
  },

  textContentHolder: {
    paddingLeft: '15%'
  },

  textContentHolderLg: {
    paddingLeft: '13%'
  },

  textContentHolderMl: {
    paddingLeft: '11%'
  },
  rootSm: {
    height: 550
  },

  dark: {
    color: palette.default.white,
    background: palette.default.black,
    '& $buttonSecondary': {
      border: `solid 2px ${palette.secondary.second}`,
      backgroundColor: 'transparent',
      '& p': {
        color: palette.secondary.second
      },
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: palette.secondary.third,
          '& p': {
            color: palette.secondary.third
          }
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: palette.secondary.third,
          '& p': {
            color: palette.secondary.third
          }
        }
      }
    },
    '& $textHolder': {
      '& a': {
        color: palette.primary.first
      }
    },
    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.white
      }
    }
  },

  light: {
    backgroundColor: palette.grey.fifth,
    color: palette.default.black,
    '& $buttonSecondary': {
      backgroundColor: palette.grey.seventh,

      '& span': {
        color: palette.default.white
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.primary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.primary.second
        }
      }
    },
    '& $textHolder': {
      '& a': {
        color: palette.secondary.first
      }
    },
    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.black
      }
    }
  },

  color: {
    backgroundColor: palette.secondary.first,
    color: palette.default.white,
    '& $buttonSecondary': {
      backgroundColor: palette.grey.seventh,

      '& span': {
        color: palette.default.white
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.primary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.primary.second
        }
      }
    },
    '& $textHolder': {
      '& a': {
        color: palette.secondary.fourth
      }
    },
    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.white
      }
    }
  },

  special: {
    backgroundColor: palette.secondary.first,
    color: palette.default.white,
    '& $buttonSecondary': {
      backgroundColor: palette.grey.seventh,

      '& span': {
        color: palette.default.white
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.primary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.primary.second
        }
      }
    },
    '& $textHolder': {
      '& a': {
        color: palette.secondary.fourth
      }
    },
    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.white
      }
    }
  },

  buttonPrimaryMd: {
    '&:hover': {
      backgroundColor: palette.secondary.second
    },
    '&:active': {
      backgroundColor: palette.secondary.third
    }
  },

  imageOverlaySvg: {
    '& g': {
      '& g': {
        fill: palette.secondary.first
      }
    }
  },

  buttonsHolderMd: {
    '& button': {
      minHeight: 48
    }
  },

  buttonsHolderSm: {
    '& button': {
      minHeight: 44
    }
  },

  iconPlayOnButton: {
    width: 24,
    height: 24
  },

  iconPlayOnButtonMd: {
    width: 20,
    height: 20
  },

  iconPlayOnButtonSm: {
    width: 16,
    height: 16
  },

  image: {
    height: 480,
    width: '100%',
    objectFit: 'cover'
  },
  imageSm: {
    objectFit: 'unset',
    height: 'auto'
  }
};

export default HeaderSectionSlide;
