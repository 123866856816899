import { palette } from '../palette/Palette';

const Footer = {
  footer: {
    backgroundColor: palette.default.black,
    marginTop: 0
  },

  holder: {
    padding: '65px 0 56px',
    paddingMd: '41px 0 140px',
    paddingSm: '41px 0 105px'
  },

  topContainer: {
    padding: '0 0 40px'
  },

  title: {
    marginBottom: 8
  },

  contact: {
    margin: '20px 0 0',

    '& svg': {
      width: 32,
      height: 32,

      '& path': {
        fill: palette.default.white
      }
    }
  },

  contactItem: {
    padding: '0 0 0 50px'
  },

  footnote: {
    padding: '10px 0 0 49px'
  },

  footerMenuItem: {
    padding: '20px 10px 4px 0',
    '&:hover': {
      '& p': {
        color: palette.grey.second
      }
    }
  },

  footerCenter: {
    paddingMd: '55px 0 0'
  },

  copyright: {
    paddingMd: '0 0 20px',
    paddingSm: '0 0 10px'
  },

  footerHolder: {
    padding: '60px 0 0'
  },

  footerForm: {
    paddingSm: '0 0 63px'
  },

  otherLink: {
    padding: '0 20px 0 0',
    paddingSm: '0 10px 10px 0',
    '&:hover': {
      '& span': {
        color: palette.default.white
      }
    }
  },

  contactsAndFootnote: {
    padding: '0 0 33px 0',
    '& $footnote': {
      color: palette.grey.second
    }
  },

  contactsAndFootnoteMd: {
    padding: 0
  },

  contactsAndFootnoteSm: {
    paddingTop: 32
  },

  contactIcons: {
    width: 21,
    height: 21
  },

  contactIcon: {
    '& path': {
      fill: '#FFFFFF'
    }
  },

  socialFooterIcons: {
    width: 40,
    height: 40
  },

  socialIcons: {
    padding: 0,
    paddingMd: '48px 0 48px',
    paddingSm: '32px 0 32px'
  },

  socialIcon: {
    height: 40,
    width: 40,
    background: palette.primary.first,
    margin: '0 16px 16px 0',
    borderRadius: 4,
    '&:hover': {
      background: palette.secondary.first
    }
  },

  iconInner: {
    height: 18,
    width: 18
  }
};

export default Footer;
