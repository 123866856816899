import { palette } from '../palette/Palette';

const ImageTextCard = {
  title: {
    color: palette.default.white
  },
  textHolder: {
    '& a': {
      '& div': {
        background: palette.secondary.second,
        '&:hover': {
          background: palette.secondary.third
        }
      }
    }
  },
  icon: {
    '& circle': {
      fill: palette.secondary.second
    }
  }
};

export default ImageTextCard;
