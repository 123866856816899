import React from 'react';

const universalVotingIconPlay = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    id="assets"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="assets/station-no-cover-Radio10-1"
      transform="translate(-42.000000, -41.000000)"
      fill="#FFFFFF"
    >
      <g
        id="radio-10-/-icons-/-general-/-play"
        transform="translate(22.000000, 22.000000)"
      >
        <path
          d="M50.4889339,34.49664 L28.5829339,20.09664 C28.0771339,19.76364 27.4381339,19.70784 26.8819339,19.94724 C26.3257339,20.18664 25.9261339,20.69064 25.8217339,21.28644 L20.7277339,50.08644 C20.6071339,50.76504 20.8861339,51.45264 21.4423339,51.85764 C21.7573339,52.08444 22.1281339,52.19964 22.5007339,52.19964 C22.7905339,52.19964 23.0803339,52.12944 23.3467339,51.98904 L50.3467339,37.58904 C50.9083339,37.29024 51.2701339,36.71784 51.2989339,36.08244 C51.3277339,35.44884 51.0199339,34.84584 50.4889339,34.49664"
          id="Combined-Shape"
        />
      </g>
    </g>
  </g>
);

export default universalVotingIconPlay;
