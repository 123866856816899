import React from 'react';

const iconLoggedIn = (
  <>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="32" cy="32" r="32" />
      <circle fill="#706F6F" cx="32" cy="32" r="32" />
      <g fill="#FFF">
        <path d="M46.509 42.714v3.572c0 .394-.32.714-.714.714H18.652a.715.715 0 0 1-.715-.714v-3.572c0-8.46 11.134-8.57 11.423-8.571h5.72s11.429 0 11.429 8.571zM32.223 17c3.156 0 5.715 3.199 5.715 7.143s-2.56 7.143-5.715 7.143-5.714-3.199-5.714-7.143S29.068 17 32.223 17z" />
      </g>
    </g>
  </>
);

export default iconLoggedIn;
