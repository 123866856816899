import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconInfo = (
  <>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="32" cy="32" r="32" />
      <circle fill={palette.primary.first} cx="32" cy="32" r="32" />
      <g fill="#FFF">
        <path d="M32.184 23.634c.93 0 1.739-.289 2.43-.866.69-.578 1.034-1.388 1.034-2.43 0-1.042-.352-1.86-1.056-2.45a3.696 3.696 0 0 0-2.45-.888c-.93 0-1.733.289-2.41.866-.675.578-1.013 1.402-1.013 2.472 0 1.042.345 1.852 1.035 2.43.69.577 1.5.866 2.43.866zM35.14 47V26.718h-5.915V47h5.915z" />
      </g>
    </g>
  </>
);

export default iconInfo;
