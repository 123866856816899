import { palette } from '../palette/Palette';

const RadioPopup = {
  modalHeader: {
    backgroundImage: palette.gradient.second,
    color: palette.default.white
  },

  rightSideContent: {
    color: palette.grey.eight
  },

  textButton: {
    '& p': {
      color: palette.grey.eight
    }
  },

  closeIconSvg: {
    '& g': {
      fill: palette.default.white
    }
  },

  firstButtonMd: {
    width: 180,
    minHeight: 48,
    '& svg': {
      width: 20,
      height: 20,
      marginRight: 15
    }
  },

  firstButtonSm: {
    width: 200,
    height: 44,
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 30
    }
  }
};

export default RadioPopup;
