import { palette } from '../../palette/Palette';

const OverlayPlayButton = {
  button: {
    // Cover Styles tileHoverIconSize
    height: 76,
    width: 76,
    // Cover Styles tileHoverColor
    backgroundColor: palette.primary.first
  },

  hover: {
    // default value from StationTilecomponent overlayIconSize
    height: 18,
    width: 18
  },

  lastListened: {
    // Last Listened Stations Styles tileHoverIconSize
    height: 40,
    width: 40
  }
};

export default OverlayPlayButton;
