import { palette } from '../palette/Palette';

const ArticleSection = {
  header: {
    marginBottom: 37
  },

  articleSectionTitle: {
    color: palette.default.black
  },

  link: {
    '&:hover': {
      '& p': {
        color: palette.secondary.third
      },
      '& polygon': {
        fill: palette.secondary.third
      }
    }
  },

  linkText: {
    color: palette.secondary.second,
    marginTop: 4
  },

  arrowIcon: {
    paddingBottom: 3,
    height: 20,
    width: 20,
    '& path': {
      fill: palette.secondary.second
    }
  },

  arrowIconMd: {
    paddingBottom: 2
  },

  arrowIconSm: {
    height: 16,
    width: 16
  }
};

export default ArticleSection;
