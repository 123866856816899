import React from 'react';

const iconPin = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M32.4 0A22.385 22.385 0 0 0 10 22.4C10 39.2 32.4 64 32.4 64s22.4-24.8 22.4-41.6A22.385 22.385 0 0 0 32.4 0zm0 30.4a8.004 8.004 0 0 1 0-16 8.004 8.004 0 0 1 0 16z"
    fill="#FF5F67"
  />
);

export default iconPin;
