import React from 'react';

const iconNavigationLogo = (
  <g fill="none" fillRule="evenodd">
    <text
      fontFamily="PoppinsBold"
      fontSize="19"
      fontWeight="bold"
      fill="#FFF"
      transform="translate(0 -4)"
    >
      <tspan x="25.267" y="20">
        Account
      </tspan>
    </text>
    <g fill="#00D473">
      <path d="M16.678 8.483c-.144 2.062-1.928 3.74-3.979 3.74-2.05 0-3.601-1.678-3.458-3.74C9.385 6.42 11.17 4.74 13.22 4.74c2.05 0 3.602 1.679 3.459 3.742zM13.479 1c-2.468 0-4.738 1.211-6.22 3.075L5.96 11.54c.981 2.607 3.455 4.424 6.478 4.424 4.107 0 7.67-3.35 7.957-7.482C20.683 4.35 17.586 1 13.479 1zM5.453 1.534c-.105 0-.23.02-.381.067L1.108 2.732c-.298.086-.485.356-.527.664l-.157.857c-.062.455.2.824.64.824h.67L.084 14.81c-.087.638.344 1.155.962 1.155h1.49c.634 0 1.22-.53 1.308-1.183L5.945 2.285s.152-.75-.492-.75" />
    </g>
  </g>
);

export default iconNavigationLogo;
