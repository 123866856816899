import { palette } from '../palette/Palette';

const TextStyles = {
  copyTextInner: {
    link: {
      color: palette.secondary.second,
      '&:hover': {
        color: palette.secondary.third
      }
    },
    subscript: {
      color: palette.grey.third
    },
    '& img': {
      objectFit: 'cover'
    },
    color: palette.default.black
  },

  callToAction: {
    marginBottom: 20
  }
};

export default TextStyles;
