import React from 'react';

const iconSliderNext = (
  <g fill="none" fillRule="evenodd">
    <g>
      <g>
        <g>
          <g
            fill="#FF5F67"
            transform="translate(-868 -1070) translate(160 561) translate(708 509)"
          >
            <circle cx="26" cy="26" r="26" />
          </g>
          <g fill="#FFF">
            <path
              d="M7.255 24.202l-2.512-2.513c-.23-.23-.23-.605 0-.837l8.246-8.246c.233-.23.233-.606 0-.837L4.743 3.523c-.23-.232-.23-.607 0-.837L7.255.173c.23-.23.606-.23.837 0L19.688 11.77c.23.23.23.606 0 .837L8.092 24.202c-.23.23-.606.23-.837 0z"
              transform="translate(-868 -1070) translate(160 561) translate(708 509) translate(13.813 13.813)"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
);

export default iconSliderNext;
