import { palette } from '../../palette/Palette';
import playerTileBackground from '../../../assets/playerTileBackground.png';

const Cover = {
  wrapper: {
    // Cover Styles margin
    marginBottom: 15
  },

  tile: {
    // Cover Styles margin tileRoundedCorners ('1.5%' for true, 0 for false)
    borderRadius: '1.5%',

    // Cover Styles tileSize [0]
    height: 276,
    width: 276,

    // Cover Styles margin
    marginBottom: 15
  },

  tileMobile: {
    // Cover Styles tileSize [1]
    height: 255,
    width: 255
  },

  image: {
    // Cover Styles backgroundColor
    backgroundImage: `url(${(playerTileBackground as any).src})`
  },

  stationName: {
    // Cover Styles tileTextColor
    color: palette.default.white,
    // Cover Styles tileTextFontFace
    fontFamily: 'Radio10Regular',
    // Cover Styles tileTextFontSize
    fontSize: 32,
    // Cover Styles tileTextLineHeight
    lineHeight: 1,
    // Cover Styles tileTextFontStyle
    textTransform: 'none'
  },

  liveIndicator: {
    // Cover Styles liveIndicatorBackgroundColor
    backgroundColor: palette.secondary.second,
    // Cover Styles liveIndicatorColor
    color: palette.default.white,
    // Cover Styles liveIndicatorTextTransform
    textTransform: 'none'
  },

  showTitle: {
    // Cover Styles tileTextFontFace
    fontFamily: 'Radio10Regular',
    // Cover Styles tileTextColor
    color: palette.default.white,
    // Cover Styles text1FontSize
    fontSize: 18,
    // Cover Styles tileTextLineHeight
    lineHeight: 1,
    // Cover Styles text1TextTransform
    textTransform: 'none'
  },

  showTime: {
    // Cover Style text2FontFace
    fontFamily: 'PoppinsBold'
  },

  textTitle: {
    // Cover Styles text1Color
    color: palette.primary.second,
    // Cover Styles text1FontFace
    fontFamily: 'Radio10Regular',
    // Cover Styles text1FontSize
    fontSize: 15,
    // Cover Styles text1LineHeight
    lineHeight: '20px',
    // Cover Styles text1FontStyle
    textTransform: 'none',
    // Cover Styles text1FontSize/2
    marginBottom: 4,
    // Cover Styles tileSize[0]
    maxWidth: 276
  },

  textArtist: {
    // Cover Styles text2Color
    color: palette.grey.first,
    // Cover Styles text2FontFace
    fontFamily: 'PoppinsBold',
    // Cover Styles text2FontSize
    fontSize: 15,
    // Cover Styles text2LineHeight
    lineHeight: '18px',
    // Cover Styles text2FontStyle
    textTransform: 'none',
    // Cover Styles text2FontSize/2
    marginBottom: 8,
    // Cover Styles tileSize[0]
    maxWidth: 276
  },

  textMobile: {
    // Cover Styles tileSize[1]
    maxWidth: 255
  }
};

export default Cover;
