import React from 'react';

const iconLogo = (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M107.2,0c-8,0-15.3,3.9-20.1,9.9l-4.2,24c3.2,8.4,11.2,14.2,21,14.2c13.3,0,24.8-10.8,25.7-24.1   C130.5,10.8,120.5,0,107.2,0z M117.6,24.1c-0.5,6.6-6.2,12-12.9,12c-6.6,0-11.6-5.4-11.2-12c0.5-6.6,6.2-12,12.9-12   C113,12,118,17.4,117.6,24.1z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M81.8,1.1c-0.3,0-0.8,0.1-1.2,0.2L67.6,4.9c-1,0.3-1.6,1.1-1.7,2.1l-0.5,2.7c-0.2,1.4,0.7,2.6,2.1,2.6h2.2   l-5.4,30.8c-0.3,2,1.1,3.7,3.1,3.7h4.9c2.1,0,4-1.7,4.3-3.7l6.9-39.5C83.4,3.5,83.9,1.1,81.8,1.1z"
      />
    </g>
    <g>
      <path
        fill="#000105"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9,14.6h-3c-0.3,0-0.5,0.2-0.6,0.4l-0.8,4.7c-0.7-0.3-1.6-0.6-2.6-0.6c-3.2,0-5.6,2.1-6.2,5.3l-0.6,3   c-0.6,3.5,1.5,6.2,5.4,6.2c1.8,0,3.8-0.4,5.3-1c0.2-0.1,0.4-0.2,0.4-0.5l3-17.1C40.3,14.8,40.2,14.6,39.9,14.6z M33.7,29.8   c-0.4,0.2-1.1,0.3-1.8,0.3c-1.4,0-2-0.7-1.7-2.3l0.5-2.8c0.3-1.6,1.1-2.4,2.4-2.4c0.7,0,1.3,0.2,1.8,0.5L33.7,29.8z"
      />
      <path
        fill="#000105"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9,19.6h-3.1c-0.3,0-0.5,0.1-0.5,0.4L41,32.9c-0.1,0.3,0.1,0.5,0.4,0.5h3.2c0.3,0,0.5-0.2,0.5-0.4l2.3-12.9   C47.4,19.8,47.2,19.6,46.9,19.6z"
      />
      <path
        fill="#000105"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48,14.1h-3.2c-0.3,0-0.5,0.1-0.5,0.4l-0.4,2.4c-0.1,0.3,0.1,0.5,0.4,0.5h3.2c0.3,0,0.5-0.2,0.5-0.4l0.4-2.4   C48.5,14.3,48.3,14.1,48,14.1z"
      />
      <path
        fill="#000105"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.1,19.2c-3.7,0-6.3,2-6.9,5.2l-0.6,3.3c-0.6,3.4,1.6,6,5.1,6c3.9,0,6.4-1.9,7-5.2l0.6-3.3   C62.9,21.9,60.8,19.2,57.1,19.2z M58.3,24.8l-0.6,3.4c-0.2,1.2-1.1,2-2.5,2c-1.3,0-1.8-0.8-1.6-2.1l0.6-3.4c0.2-1.2,1.1-2,2.4-2   C57.9,22.7,58.5,23.6,58.3,24.8z"
      />
      <path
        fill="#000105"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5,19.2c-2.2,0-4.2,0.5-5.4,1.2c-0.2,0.1-0.2,0.3-0.1,0.5l0.8,2.2c0.1,0.3,0.3,0.3,0.6,0.2   c0.8-0.4,2-0.6,3.2-0.6c1.7,0,2,0.6,1.8,2l-0.1,0.4c-0.7-0.1-1.5-0.2-2.3-0.2c-3.4,0-5.6,1.1-6.1,3.7c-0.5,2.9,1.4,5,5.1,5   c2.1,0,4-0.4,5.5-0.9c0.2-0.1,0.3-0.3,0.4-0.5l1.2-6.7C24,21.6,22.1,19.2,18.5,19.2z M18.5,30.1c-0.5,0.1-1.2,0.2-1.8,0.2   c-1.2,0-1.8-0.5-1.6-1.5c0.1-0.8,0.8-1.2,2.1-1.2c0.6,0,1.2,0.1,1.7,0.2L18.5,30.1z"
      />
      <path
        fill="#000105"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7,19.4c-0.8-0.2-1.5-0.2-2.2-0.2c-1.7,0-3.4,0.4-4.9,1.1c-0.2,0.1-0.3,0.3-0.4,0.5L0,32.9   c-0.1,0.3,0.1,0.5,0.4,0.5h3.1C3.8,33.4,4,33.3,4,33l1.7-9.8c0.5-0.2,1-0.3,1.7-0.3c0.6,0,1.1,0.1,1.6,0.1c0.3,0,0.5-0.1,0.5-0.4   l0.5-2.6C10.1,19.7,10,19.5,9.7,19.4z"
      />
    </g>
  </>
);

export default iconLogo;
