import { palette } from '../../palette/Palette';

const Dialog = {
  content: {
    // Dialog Styles textColor
    color: palette.grey.first,
    // Dialog Styles backgroundColor
    background: '#fafafa',
    // Dialog Styles boxShadow
    boxShadow: '0px 0px 32px -5px rgba(0,0,0,.25)'
  },

  fontPargraph: {
    // Dialog Styles textFontSize
    fontSize: 16,
    // Dialog Styles textFontFace
    fontFamily: 'UniNeueBold'
  },

  hrColor: {
    // Dialog Styles rulerColor
    color: '#cccccc',
    // Dialog Styles rulerColor
    backgroundColor: '#cccccc'
  },

  hrBefore: {
    // Dialog Styles secondaryButtonColor
    backgroundColor: '#ff3750',
    // Dialog Styles backgroundColor
    border: '6px solid #fafafa'
  },

  title: {
    // Dialog Styles headingColor
    color: palette.primary.second,
    // Dialog Styles headingFontFace
    fontFamily: 'Radio10Regular',
    // Dialog Styles secondaryButtonColor
    fontSize: 16
  }
};

export default Dialog;
