import React from 'react';

const dropIcon = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <path
      d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32zm0-6C17.64 58 6 46.36 6 32S17.64 6 32 6s26 11.64 26 26-11.64 26-26 26z"
      fill="#D0D0D0"
    />
  </g>
);

export default dropIcon;
