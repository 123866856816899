import { palette } from '../palette/Palette';

const Switch = {
  switch: {
    borderColor: palette.grey.third,
    backgroundColor: palette.grey.third
  },

  switchAfter: {
    backgroundColor: palette.grey.second
  },

  checkedAfter: {
    backgroundColor: palette.primary.first
  }
};
export default Switch;
