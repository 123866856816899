import { palette } from '../../palette/Palette';

const BackNavigation = {
  wrapper: {
    // Navigation Styles borderColor 2px solid is same for all brands
    borderBottom: '2px solid #ececec'
  },

  backButton: {
    // Navigation Styles iconColor
    color: palette.primary.second,

    // Navigation Styles fontFace
    fontFamily: 'Radio10Regular',

    // Navigation Styles fontSize
    fontSize: 20,

    // Navigation Styles fontStyle
    textTransform: 'none'
  },

  // Navigation Styles hoverColor
  hoverColor: {
    color: palette.primary.first
  }
};

export default BackNavigation;
