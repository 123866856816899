import { palette } from '../palette/Palette';

const Clip = {
  bottomPlayOptions: {
    height: 50,
    backgroundColor: palette.primary.first,
    borderBottomRightRadius: 4
  },

  audioHolder: {
    backgroundColor: palette.grey.fifth,
    '@media (hover: hover)': {
      '&:hover': {
        '& $imageHolder': {
          '& $imageOverlay': { opacity: 0.6 }
        },

        '& $imageHolderLatest': {
          '& $imageOverlay': { opacity: 0.6 }
        },
        '& $bottomPlayOptions': {
          backgroundColor: palette.secondary.first
        }
      }
    },
    '@media (hover: none)': {
      '& $imageHolder': {
        '& $imageOverlay': { opacity: 0 }
      },

      '& $imageHolderLatest': {
        '& $imageOverlay': { opacity: 0 }
      },
      '& $bottomPlayOptions': {
        backgroundColor: palette.primary.first
      }
    }
  },

  imageOverlay: {
    backgroundColor: palette.primary.first
  },

  bottomPlayOptionsMd: {
    height: '35px'
  },

  notPlaying: {
    width: '72px',
    paddingRight: 12,
    '& svg': {
      fill: palette.primary.first
    }
  },

  notPlayingMd: {
    width: '56px',
    paddingRight: 10
  },

  notPlayingSm: {
    width: '46px'
  },

  playing: {
    width: '72px',
    paddingRight: 10,
    '& svg': {
      fill: palette.secondary.first
    },
    boxSizing: 'initial'
  },

  playingMd: {
    width: '88px'
  },

  playingSm: {
    width: '74px'
  },

  audioHolderInner: {
    maxHeight: 112
  },

  iconStop: {
    height: 40,
    width: 40
  },

  iconStopLatest: {
    height: 26,
    width: 26
  },

  iconStopLatestMd: {
    height: 21,
    width: 21
  },

  iconStopMd: {
    right: 32,
    height: 34,
    width: 34
  },

  iconStopSm: {
    right: 22,
    height: 34,
    width: 34
  },

  imageHolder: {
    width: '17.9%'
  },

  imageHolderMd: {
    width: 70
  },

  imageHolderSm: {
    width: 56
  },

  imageHolderLatest: {
    width: '37.1%',
    '&:hover': {
      '& $imageOverlay': { opacity: 0.7 },
      '& $iconPlayLarge': { opacity: 1 }
    }
  },

  imageHolderLatestMd: {
    width: 150
  },

  imageLatest: {
    maxWidth: 234
  },

  imageLatestMd: {
    maxWidth: 150
  },

  iconSpeaker: {
    height: 32,
    width: 32,
    '& svg': {
      fill: palette.secondary.first
    }
  },

  iconSpeakerMd: {
    right: 77,
    height: 27,
    width: 27
  },

  iconSpeakerSm: {
    right: 67,
    height: 22,
    width: 22
  },

  badgeHolder: {
    top: 13,
    left: 13,
    zIndex: 10,
    textTransform: 'capitalize'
  },

  iconSpeakerLatest: {
    height: 32,
    width: 32
  },

  iconSpeakerLatestMd: {
    height: 27,
    width: 27
  },

  image: {
    maxWidth: '112px'
  },

  imageMd: {
    width: '70px'
  },

  imageSm: {
    width: '56px'
  },

  textHolderLatest: {
    height: '78.6%'
  },

  textHolderLatestMd: {
    height: 'calc(100% - 35px)'
  },

  textplay: {
    textTransform: 'lowercase',
    '& ::first-letter': {
      textTransform: 'uppercase'
    }
  },

  iconPlayStopLatest: {
    height: 26,
    width: 26,
    '& circle': {
      fill: palette.default.white
    },
    '& g': {
      fill: palette.primary.first
    },
    padding: '0 2% 0 5%'
  },

  iconPlayStopLatestMd: {
    height: 21,
    width: 21
  },

  iconPlay: {
    height: 40,
    width: 40
  },

  iconPlayMd: {
    height: 34,
    width: 34
  },

  textHolderInner: {
    left: '7%',
    '& svg': {
      fill: palette.secondary.first
    },
    textTransform: 'lowercase'
  },

  textHolder: {
    padding: '1% 4%'
  },

  textHolderInnerSm: {
    left: '10px'
  },

  iconPlayLarge: {
    width: 60,
    height: 60
  }
};

export default Clip;
