const TileMessageButton = {
  button: {
    // Dialog Styles secondaryButtonColor
    backgroundColor: '#ff3750',

    // Cover Styles text2FontFace
    fontFamily: 'PoppinsBold',

    // Cover Styles text2FontStyle
    fontStyle: 'none'
  }
};

export default TileMessageButton;
