import React from 'react';

const iconPauseOnButton = (
  <path
    d="M61.058 6.001a2.939 2.939 0 0 1 2.898 3.452L55.652 56.53a2.941 2.941 0 0 1-2.895 2.433H35.103a2.943 2.943 0 0 1-2.898-3.454l8.3-47.077a2.942 2.942 0 0 1 2.899-2.43zM28.897 6c.868 0 1.695.385 2.254 1.05.559.665.794 1.545.644 2.404l-8.3 47.078a2.947 2.947 0 0 1-2.899 2.43H2.942a2.944 2.944 0 0 1-2.898-3.454l8.3-47.078A2.947 2.947 0 0 1 11.244 6z"
    fill="#FFF"
  />
);

export default iconPauseOnButton;
