import React from 'react';

const iconLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 900 400"
    enableBackground="new 0 0 900 400"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#00D473"
        d="M554.6,58.4c-2.1,0-4.6,0.4-7.7,1.3l-79.7,22c-6,1.7-9.7,6.9-10.6,12.9l-3.2,16.7c-1.2,8.9,4,16.1,12.9,16.1   h13.5l-33.2,189.6c-1.7,12.4,6.9,22.5,19.3,22.5h30c12.7,0,24.5-10.3,26.3-23L564.5,73C564.5,73,567.6,58.4,554.6,58.4z"
      />
      <path
        fill="#00D473"
        d="M710.9,51.7c-49.2,0-94.4,24-123.9,60.8l-25.8,147.8c19.5,51.6,68.8,87.5,129,87.5   c81.8,0,152.7-66.3,158.4-148.1C854.4,118,792.7,51.7,710.9,51.7z M774.6,199.7c-2.9,40.8-38.4,74-79.2,74   c-40.8,0-71.7-33.2-68.9-74c2.9-40.8,38.4-74,79.2-74C746.6,125.7,777.5,158.9,774.6,199.7z"
      />
    </g>
    <g>
      <path
        fill="#000105"
        d="M164.8,169.8c-13.3,0-25.6,2.9-33.5,7.6c-1,0.7-1,1.8-0.5,3.3l5.1,13.3c0.7,1.8,2,2.1,3.8,1.3   c4.9-2.3,12.3-3.7,19.5-3.7c10.3,0,12.3,3.9,11.2,12.4l-0.3,2.6c-4.1-0.8-9-1.3-14-1.3c-21,0-34.5,7-37.3,22.8   c-3.3,17.7,8.4,30.8,31.4,30.8c12.6,0,24.8-2.4,34.2-5.7c1.5-0.5,2.1-1.6,2.5-3.1l7.4-41.2C198.6,184.4,187.1,169.8,164.8,169.8z    M164.6,237.2c-3,0.7-7.6,1.3-11.3,1.3c-7.2,0-11.2-2.9-10-9.1c0.8-4.9,5.1-7.5,13.1-7.5c3.6,0,7.4,0.5,10.5,1.1L164.6,237.2z"
      />
      <path
        fill="#000105"
        d="M296.3,141.5h-18.4c-1.6,0-3.2,1-3.5,2.6l-5.1,28.8c-4.1-2.1-9.9-3.4-15.9-3.4c-19.5,0-34.5,13.2-37.9,32.7   l-3.4,18.8c-3.8,21.7,9.4,37.9,33.2,37.9c10.8,0,23.5-2.3,32.8-6c1.5-0.5,2.3-1.5,2.5-2.9l18.5-105.3   C299.3,142.8,298.3,141.5,296.3,141.5z M258.3,235.3c-2.6,1.1-6.9,1.8-10.8,1.8c-8.9,0-12.5-4.1-10.5-14.4l3.1-17.5   c1.8-10,6.9-14.9,14.8-14.9c4.1,0,8.2,1,10.8,2.8L258.3,235.3z"
      />
      <path
        fill="#000105"
        d="M339.4,172.5h-19.3c-1.7,0-2.8,0.8-3,2.5l-14.1,79.4c-0.3,1.8,0.7,3.1,2.5,3.1H325c1.8,0,2.8-1,3.2-2.6   l13.9-79.4C342.4,173.6,341.4,172.5,339.4,172.5z"
      />
      <path
        fill="#000105"
        d="M346.1,138.8h-19.8c-1.7,0-2.9,0.8-3,2.4l-2.7,14.7c-0.3,1.8,0.7,3.1,2.5,3.1h19.8c1.9,0,2.9-1,3.2-2.6   l2.7-14.7C349.2,139.9,348.2,138.8,346.1,138.8z"
      />
      <path
        fill="#000105"
        d="M402.4,169.8c-22.9,0-39,12.2-42.6,31.9l-3.5,20.2c-3.8,20.7,9.6,36.9,31.6,36.9c24.2,0,39.2-11.7,43-32.2   l3.8-20.5C438.1,186.2,425,169.8,402.4,169.8z M409.6,204.5l-3.8,20.8c-1.3,7.7-6.6,12.2-15.1,12.2c-7.7,0-11.2-5.2-9.7-13.2   l3.8-21c1.3-7.3,6.9-12.2,14.8-12.2C407.5,191.1,411,197.1,409.6,204.5z"
      />
      <path
        fill="#000105"
        d="M110.5,171.3c-4.8-1-9.2-1.5-13.7-1.5c-10.4,0-20.9,2.5-30.5,6.9c-1.3,0.7-2.1,1.6-2.5,3.1l-13,74.5   c-0.3,1.8,0.7,3.1,2.6,3.1h19.1c1.6,0,2.6-1,3-2.6l10.7-60.6c2.8-1.1,6.4-2,10.7-2c3.5,0,6.8,0.3,9.6,0.8c1.8,0.2,3.1-0.7,3.3-2.5   l3-16.1C113.1,172.9,112.3,171.6,110.5,171.3z"
      />
    </g>
  </svg>
);

export default iconLogo;
