import React from 'react';

const iconNowPlaying = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <g fill="#FFF">
      <path d="M54.384 9.616c.488.488.488 1.28 0 1.768s-1.28.488-1.768 0C41.25.018 22.751.017 11.384 11.384 4.026 18.742 1.235 29.35 3.774 39.276c1.284 5.016 3.876 9.606 7.61 13.34 6.095 6.095 14.443 9.093 22.897 8.436 6.885-.535 13.364-3.465 18.335-8.436.488-.488 1.28-.488 1.768 0s.488 1.28 0 1.768c-5.397 5.397-12.436 8.58-19.91 9.16-9.174.713-18.24-2.542-24.858-9.16-4.053-4.054-6.87-9.041-8.263-14.488-2.756-10.771.273-22.29 8.263-30.28 12.343-12.343 32.425-12.342 44.768 0zm-27.885 9.328c.453-.195.972-.15 1.384.121L45.7 30.777c.432.284.682.775.658 1.29-.023.517-.317.982-.774 1.225l-21.96 11.712c-.217.115-.452.172-.688.172-.303 0-.605-.094-.861-.278-.452-.33-.68-.89-.581-1.441l4.143-23.424c.085-.485.41-.895.862-1.09z" />
    </g>
  </g>
);

export default iconNowPlaying;
