import withErrorBoundary from '@talpanetwork/talpa-publishing-components/ErrorBoundary';
import { ContentHolder } from '@talpanetwork/talpa-publishing-core/ContentHolder/ContentHolder';
import {
  mapGraphQLResponsePage,
  getRouteData,
  fetchArticlesByCategoryId,
  fetchOverviewBySlug,
  mapGraphQLResponse,
  setInitialApplicationValues,
  setOverviewMetaData,
  setArticleMetaData,
  setCategoryMetaData,
  setSearchMetaData,
  getPageData,
  attachDomaintoPath,
  setPageType
} from '@talpanetwork/talpa-publishing-core/routing';
import {
  startPlayback,
  stopPlayback,
  externalMediaEnded,
  loadStation,
  loadMedia,
  showMiniPlayer,
  hideMiniPlayer
} from '@talpanetwork/talpa-publishing-core/redux-store/actions/radioPlayerActions/player.action';
import { radioplayerEventBus } from '@talpanetwork/radio-player';
import {
  getPlaybackStatus,
  getMedia
} from '@talpanetwork/talpa-publishing-core/redux-store/reducers/radioPlayerReducers/player.reducer';
import { getApplicationLandingPageData } from '@talpanetwork/talpa-publishing-core/redux-store/utils';
import {
  useContentContext,
  ContentContextProvider
} from '@talpanetwork/talpa-publishing-core/ContextProvider/ContextProvider';
import { BrowserHistoryContextProvider } from '@talpanetwork/talpa-publishing-core/HistoryProvider';
import Gigya from '@talpanetwork/talpa-publishing-components/gigya';
// import Tealium from '@talpanetwork/talpa-publishing-components/tealium';
import {
  addTealiumInstance,
  setApplicationDataFromJson,
  getAppRoute,
  setAppRoute,
  getVotingRoute,
  setVotingRoute
} from '@talpanetwork/talpa-publishing-core/services';

import { JssThemeProvider } from '@talpanetwork/talpa-publishing-theme/theming/ThemeProvider';
import { generateTheme } from '@talpanetwork/talpa-publishing-theme/theming/generateTheme';
import { injectSheet } from '@talpanetwork/talpa-publishing-theme/theming/injectSheet';
import getContentfulService from '@talpanetwork/talpa-publishing-core/services/Contentful.service';
import {
  isOverviewPage,
  checkUser,
  setUser,
  unsetUser,
  IAuthAction,
  setArticleView,
  onPlay,
  onStop,
  onStopAllExceptMe,
  setStationSlug,
  clearStreamInfo,
  setStreamCategory
} from '@talpanetwork/talpa-publishing-core/redux-store/actions/index';
import {
  useStore,
  initializeStore
} from '@talpanetwork/talpa-publishing-core/redux-store/store';
import Content from '@talpanetwork/talpa-publishing-components/contentful';
import {
  UniversalVotingTextContextProvider,
  UniversalVotingContentContextProvider
} from '@talpanetwork/talpa-publishing-components/UniversalVoting/contexts';

import { isGoogleBot } from '@talpanetwork/talpa-publishing-core/utils/googleBot.utils';
import { showScreenSet } from '@talpanetwork/talpa-publishing-core/utils/gigyaScreenSet.utils';
import { truncateOneLine } from '@talpanetwork/talpa-publishing-core/utils/article.utils';
import {
  fetchWithoutHeader,
  postWithoutHeader
} from '@talpanetwork/talpa-publishing-core/services/fetch.service';
import Meta from '@talpanetwork/talpa-publishing-core/Meta';
import {
  isAuthorized,
  generateRoute
} from '@talpanetwork/talpa-publishing-core/utils/route.utils';
import withSuspense from '@talpanetwork/talpa-publishing-components/withSuspense';

import {
  OverviewSectionItemModel,
  ContentfulModel,
  ImageModel,
  OverviewModel,
  ArticleFieldsModel,
  CategoryFields,
  SliderGalleryItemModel,
  SvgIconProps
} from '@type/index';

import dynamic from 'next/dynamic';

import React, { FunctionComponent } from 'react';

import content from '@services/Contentful.service';
import tealium from '@services/Tealium.service';
import provider from '@services/StationsProvider.service';

import gigyaScreenSets from '@services/Gigya.service';
import { ThemeProps } from '@theme/ThemeProps.model';
// UTAG CHANGE added Tealium in imports
const Tealium = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/UTag'))
);

const OverviewComponent: React.ComponentType<{
  data?: OverviewModel;
  classes?: any;
  homeSlug?: string;
  showLabelOnArticleCards?: string | null;
  fallbackImage?: any;
}> = withErrorBoundary(
  dynamic(() => import('@components/Overview'), {
    ssr: true
  })
);

const SearchPage: React.ComponentType<{
  fallbackImageUrl: string;
  showLabelOnArticleCards: string | null;
  searchTeasers: any[];
  searchCategories: any[];
  getCardData: (...args: any[]) => any;
}> = withErrorBoundary(
  dynamic(() => import('@components/Search/Search.page'), {
    ssr: true
  })
);

const ArticleCard: React.ComponentType<{
  key: any;
  variant: string;
  article: any;
  showLabelOnArticleCards: string | null;
  getCardData?: (...args: any[]) => any;
  tealiumSearchClick?: () => void;
  fallbackImageUrl?: string;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ArticleCard'),
    {
      ssr: false
    }
  )
);

const CategoryComp: React.ComponentType<{
  content: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Category'), {
    ssr: false
  })
);

const ContentfulRichText: React.ComponentType<{
  hasBanner?: boolean;
  data: any;
  fallbackImage: ImageModel;
}> = withErrorBoundary(
  dynamic(() => import('@components/ContentfulRichText/ContentfulRichText'), {
    ssr: true
  })
);

const EmbedVideo: React.ComponentType<{
  onPlay: (...args: any[]) => void;
  onPause: (...args: any[]) => void;
  onEnd: (...args: any[]) => void;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/EmbedVideo'),
    {
      ssr: true
    }
  )
);

const ArticleHeader: React.ComponentType<{
  data: any;
  videoEmbedPlayer: any;
  dateAndCatFirst?: boolean;
  enableAutoplay?: boolean;
  hasHeaderSlide?: boolean;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ArticleHeader'),
    {
      ssr: true
    }
  )
);

const ArticleSponsored: React.ComponentType<{
  sponsoredLabel: string;
  sponsoredLogo: any;
  sponsoredUrl: string;
  isBottom: boolean;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ArticleSponsored'),
    {
      ssr: true
    }
  )
);

const VideoSection: React.ComponentType<{
  onPlay: (...args: any[]) => void;
  onPause: (...args: any[]) => void;
  onEnd: (...args: any[]) => void;
  onListItemClick: (...args: any[]) => any;
  content: ContentfulModel<OverviewSectionItemModel>;
  fallbackImage?: any;
  getLatestVideosByCategory?: (...args: any[]) => any;
  getVideosByCategory?: (...args: any[]) => any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/VideoSection'),
    { ssr: false }
  )
);

const VideoEmbedPlayer: React.ComponentType<{ video: any }> = withErrorBoundary(
  dynamic(
    () =>
      import(
        '@components/Article/content/ArticleContent/VideoEmbedPlayer/VideoEmbedPlayer'
      ),
    {
      ssr: true
    }
  )
);

const NotFoundComponent: React.ComponentType<{
  text?: any;
  categories?: any;
  teasers?: any;
  showLabelOnArticleCards?: string | null;
  getCardData?: (...args: any[]) => any;
  getCategoryData?: (...args: any[]) => any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/PageNotFound'),
    {
      ssr: true
    }
  )
);

const SingleSection: React.ComponentType<{
  section: ContentfulModel<OverviewSectionItemModel>;
  index: number;
  homeSlug: string;
  fallbackImage: any;
  showLabelOnArticleCards: string;
  key: number;
  landingPage: boolean;
}> = withErrorBoundary(
  dynamic(() => import('@components/Overview/SingleSection/SingleSection'), {
    ssr: true
  })
);

const MasonrySection: React.ComponentType<{
  fallbackImage?: any;
  section?: any;
  masonrySection?: any;
  showLabelOnArticleCards?: string;
}> = withErrorBoundary(
  dynamic(() => import('@components/Overview/MasonrySection/MasonrySection'), {
    ssr: false
  })
);

const SliderGallery: React.ComponentType<{
  item: SliderGalleryItemModel;
}> = withErrorBoundary(
  dynamic(() => import('@components/SliderGallery/SliderGallery'), {
    ssr: false
  })
);

const SliderGalleryImage: React.ComponentType<{
  item: SliderGalleryItemModel;
  settings: object;
  renderedVideoEmbedPlayer?: FunctionComponent<any>;
}> = withErrorBoundary(
  dynamic(
    () =>
      import('@talpanetwork/talpa-publishing-components/SliderGalleryImage'),
    {
      ssr: false
    }
  )
);

const Hidden: React.ComponentType<{
  mdDown?: boolean;
  smUp?: boolean;
  children?: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Hidden'), {
    ssr: false
  })
);

const Quote: React.ComponentType<
  {
    content?: object;
    className?: string;
  } & ThemeProps
> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Quote'), {
    ssr: false
  })
);

const Masonry: React.ComponentType<
  {
    items?: any[];
    variant?: 'section' | 'withLoading';
    fillGapsCallback?(...args: any[]): any;
    loadMoreCallback?(...args: any[]): any;
    className?: string;
    breakPoint?: number;
    category?: string;
    page?: number;
    total?: number;
    getTotal?: boolean;
    moreText?: string;
    categoryPage?: boolean;
    renderAudioEmbed?(...args: any[]): any;
    iconPosition?: string;
    renderVotingCardEmbed?(...args: any[]): any;
    showLabelOnArticleCards?: string;
  } & ThemeProps
> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Masonry'), {
    ssr: false
  })
);

const Typography: React.ComponentType<{
  variant?: string;
  className?: any;
  'data-testid'?: string;
  color?: string;
  component?: string;
  fontWeight?: string;
  children: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Typography'), {
    ssr: true
  })
);

const Grid: React.ComponentType<{
  children: any;
  container: true;
  spacing: number;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Grid'), {
    ssr: false
  })
);

const SearchBar: React.ComponentType<{
  variant: string;
  onSearch: (...args: any[]) => any;
  clearHandler: (...args: any[]) => any;
  focus: boolean;
  param: string;
  blurHandler: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/SearchBar'), {
    ssr: false
  })
);

const Button: React.ComponentType<{
  children: any;
  onClick: (...args: any[]) => any;
  'data-label': string;
  'data-category': string;
  'data-action': string;
  className: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Button'), {
    ssr: false
  })
);

const SearchListItem: React.ComponentType<{
  content: any;
  textHighlight: string;
  uppercaseTitle?: boolean;
  fallbackImageUrl: string;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/SearchListItem'),
    { ssr: false }
  )
);

const Banner: React.ComponentType<{
  content: string;
  banners: unknown;
  afterSecondSection?: boolean;
  isBannerInView?: boolean;
  removeId?: boolean;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Banner'), {
    ssr: false
  })
);

const SocialShare: React.ComponentType<
  {
    title: string;
    url?: string;
    universalShare?: boolean;
    socialShareText?: string;
    articleSys?: {
      id?: string;
    };
    article?: {
      title?: string;
    };
  } & ThemeProps
> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/SocialShare'),
    { ssr: false }
  )
);

const ArticleComposite: React.ComponentType<{
  content: object;
  classes?: object;
  renderRte?(...args: any[]): any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ArticleComposite'),
    { ssr: true }
  )
);

const TextFrame: React.ComponentType<{
  content: { [key: string]: any };
  classes?: any;
  renderRte?: (...args: any[]) => any;
  className?: string;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/TextFrame'), {
    ssr: true
  })
);

const ImageSection: React.ComponentType<{
  section: any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ImageSection'),
    { ssr: false }
  )
);

const ChartList: React.ComponentType<{
  source: string;
  article?: boolean;
  classes?: object;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/ChartList'))
);

const FAQComponent: React.ComponentType<{
  section: any;
  renderRte?: (...args: any[]) => any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/FAQComponent'),
    { ssr: false }
  )
);

const CompositeList: React.ComponentType<{
  section: any;
  renderRte?: (...args: any[]) => any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/CompositeList'),
    { ssr: false }
  )
);

const Section: React.ComponentType<{
  section: unknown;
  masonrySection?: any;
  content?: unknown;
  showLabelOnArticleCards?: string;
  contentFullWidth?: boolean;
  withHeader?: boolean;
  playIconBigSizes?: {
    mobile: { width: number; height: number };
    tablet: { width: number; height: number };
    pc: { width: number; height: number };
  };
  playIconSmallSizes?: {
    mobile: { width: number; height: number };
    tablet: { width: number; height: number };
    pc: { width: number; height: number };
  };
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Section'), {
    ssr: true
  })
);

const NoResults: React.ComponentType<{
  categories: any[];
  defaultImage: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/NoResults'), {
    ssr: false
  })
);

const Text: React.ComponentType<{
  classes?: { [key: string]: any };
  variant?: any;
  renderRte?: (...args: any[]) => any;
  item: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Text'), {
    ssr: false
  })
);

const SocialEmbed: React.ComponentType<{
  htmlCode?: string;
  classes?: Record<string, string>;
  embedType: any;
  embedValue: string;
  path: string;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/SocialEmbed'),
    { ssr: false }
  )
);

const IFrame: React.ComponentType<
  {
    content: object;
    path?: string;
  } & ThemeProps
> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/IFrame'), {
    ssr: false
  })
);

const Calendar: React.ComponentType<{ section: any }> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Calendar'), {
    ssr: true
  })
);

const ProgramSection: React.ComponentType<{
  section?: any;
  variant?: string;
  profile?: string;
  titleWithBackground?: boolean;
  slidesWithBackgroundImgs?: boolean;
  classes?: any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ProgramSection'),
    { ssr: true }
  )
);

const StationPicker: React.ComponentType<{
  section: any;
  classes?: any;
  className?: string;
  variant?: 'slider' | 'grid' | 'grid-list';
  titleWithBackground?: boolean;
  startRadio?: (slug: string) => void;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/StationPicker'),
    { ssr: true }
  )
);

const StationPickerSection: React.ComponentType<{
  section: ContentfulModel<any>;
}> = withErrorBoundary(
  dynamic(
    () => import('./components/Overview/StationPicker/StationPickerSection'),
    { ssr: true }
  )
);

const FragmentJackpotSection: React.ComponentType<{
  classes?: any;
  content?: any;
  customNoResults?: string;
  customSearch?: string;
  onlyAnswers?: boolean;
  type: string;
}> = withErrorBoundary(
  dynamic(
    () =>
      import(
        '@talpanetwork/talpa-publishing-components/FragmentJackpotSection'
      ),
    { ssr: true }
  )
);

const VotingCard: React.ComponentType<{
  classes?: any;
  content?: any;
  auth?: any;
  showLogin?: () => void;
  showRegistration?: () => void;
  isPlayerPlaying?: boolean;
  playStopHandler?: (isPlayingAudio: boolean) => void;
  brandName: string;
  key?: string;
}> = withErrorBoundary(
  dynamic(
    () =>
      import('@talpanetwork/talpa-publishing-components/VotingCard/VotingCard')
  )
);

const VotingCardEmbed: React.ComponentType<{
  auth?: { gigya: any };
  content: any;
  checkUser?: () => void;
  getPlaybackStatus?: () => void;
  stopPlayback?: () => void;
}> = withErrorBoundary(
  dynamic(() => import('./components/VotingCardEmbed/VotingCardEmbed'))
);

const UniversalVoting: React.ComponentType<
  {
    brand: string;
    isPlayerPlaying?: boolean;
    playStopHandler?: (isPlayingAudio: boolean) => void;
    showLogin?: () => void;
    showRegistration?: () => void;
    auth?: any;
    title?: string;
    renderRte?: (data: any) => void;
    radioPlayerEventBus: any;
  } & ThemeProps
> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/UniversalVoting'),
    { ssr: true }
  )
);

const UniversalVotingEmbed: React.ComponentType<{
  content: any;
}> = withErrorBoundary(
  dynamic(
    () => import('./components/UniversalVotingEmbed/UniversalVotingEmbed'),
    { ssr: true }
  )
);

const Frequency: React.ComponentType<{
  content?: { sys?: any; fields?: any };
  classes?: any;
  theme?: any;
  brand?: string;
  renderRte?: (data: any) => void;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Frequency'), {
    ssr: true
  })
);

const RelatedArticlesList: React.ComponentType<{
  classes?: Record<string, any>;
  article: {
    fields: Record<string, any>;
  };
  content?: object;
  fallbackImage: object;
  hideIcons?: boolean;
  isTitleUppercase?: boolean;
  relatedArticlesTitle?: string;
}> = withErrorBoundary(
  dynamic(
    () =>
      import('@talpanetwork/talpa-publishing-components/RelatedArticlesList'),
    { ssr: true }
  )
);

const RelatedArticles: React.ComponentType<{
  classes?: object;
  article: object;
  banners?: object;
  content?: object;
  includeBanner?: boolean;
  showLabelOnArticleCards?: string;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/RelatedArticles'),
    { ssr: false }
  )
);

const Helmet: React.ComponentType<{
  title: string;
  link: any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/Helmet'), {
    ssr: false
  })
);

// UTAG CHANGE
const SearchPageCategories: React.ComponentType<{
  searchCategories: any;
  defaultImage: any;
  searchParametar: any;
  total: any;
  totalCategories: any;
  isDefaultCategories: boolean;
}> = withErrorBoundary(
  dynamic(
    () =>
      import('@talpanetwork/talpa-publishing-components/SearchPageCategories'),
    {
      ssr: false
    }
  )
);

const JustIn: React.ComponentType<{
  section: any;
  getArticlesList: () => any;
  fallbackImageUrl?: string;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/JustIn'), {
    ssr: false
  })
);

const NotificationPopup: React.ComponentType<ThemeProps> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/NotificationPopup'),
    { ssr: false }
  )
);

const Modal: React.ComponentType<{
  isOpen: boolean;
  modalContent: any;
  handleClose: (...args: any[]) => void;
  handleAction?: (...args: any[]) => void;
  isAffiliatedLink?: boolean;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Modal'), {
    ssr: false
  })
);

const Toaster: React.ComponentType<{
  toasterTitle: string;
  toasterBody: string;
  toasterLinkText: string;
  toasterLink: string;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/Toaster'), {
    ssr: false
  })
);

const RadioPlayer: React.ComponentType<{
  autoPlay: boolean;
  brand: string;
  space: string;
  token: string;
  environment: string | undefined;
  defaultStation: string;
  stationSlug: string | undefined;
  enableDisplayAds: boolean;
  idBannerPlayerLarge: string;
  idBannerPlayerSmall: string;
  idBannerTrackList: string;
  popoutUrl: string;
  hasPopOutButton: boolean;
  coverArtImage: string;
  contactFormRef: any;
  setIsPlayerExpanded: () => any;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/radio-player'), {
    ssr: false,
    loading: () => <SidebarSkeleton />
  })
);

const SidebarSkeleton: React.ComponentType<ThemeProps> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/SidebarSkeleton'),
    { ssr: true }
  )
);

// LOCAL COMPONENT IMPORTS
const ArticleComponent: React.ComponentType<{
  data: ContentfulModel<ArticleFieldsModel>;
  articleData: any;
  showRelated: boolean;
  addBottomBoundary: () => void;
  advertorialCategory: ContentfulModel<CategoryFields>;
  relatedArticlesTitle: string;
  fallbackImage: ImageModel;
  setArticleView: (data: ContentfulModel<ArticleFieldsModel>) => void;
  authSharedText: string;
  authSharedDesc: string;
  sponsoredArticleTitle: string;
  shareText: string;
}> = withErrorBoundary(
  dynamic(() => import('@components/Article'), {
    ssr: true
  })
);

const ArticleContent: React.ComponentType<{
  data: ContentfulModel<ArticleFieldsModel>;
  fallbackImage: ImageModel;
  hasBannerShown: boolean;
  relatedArticlesTitle: string;
  shareText: string;
  authorizedOverlayText: string;
  authorizedOverlayDescription: string;
  authSharedText: string;
  authSharedDesc: string;
  gigyaScreenSets: any;
}> = withErrorBoundary(
  dynamic(() => import('@components/Article/content/ArticleContent'), {
    ssr: false
  })
);

const ChartListTop50: React.ComponentType<{
  source: string;
  logo: string;
  url: string;
  linkText: string;
  link: any;
  download: any;
  playStopHandler: (isPlayingAudio: any) => void;
  isPlayerPlaying: boolean;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ChartListTop50'),
    {
      ssr: false
    }
  )
);

const ChartListTop50Embed: React.ComponentType<{
  source: string;
  logo: string;
  url: string;
  linkText: string;
  link: any;
  download: any;
}> = withErrorBoundary(
  dynamic(() => import('@components/ChartListTop50Embed/ChartListTop50Embed'), {
    ssr: true
  })
);

const AudioEmbedPlayer: React.ComponentType<{
  classes?: any;
  item?: any;
  fields?: any;
  content?: any;
  variant?: string;
  type?: string;
}> = withErrorBoundary(
  dynamic(
    () =>
      import(
        '@components/Article/content/ArticleContent/AudioEmbedPlayer/AudioEmbedPlayer'
      ),
    {
      ssr: false
    }
  )
);

const AudioEmbed: React.ComponentType<{
  item: any;
  isPlayerPlaying: () => any;
  media: any;
  playStopHandler: () => any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/AudioEmbed'),
    {
      ssr: false
    }
  )
);

const LoginOverlay: React.ComponentType<{
  authPageText: string;
  authPageDesc: string;
  authSharedtext: string;
  authSharedDesc: string;
  gigyaScreenSets: any;
  Authenticator: any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/LoginOverlay'),
    {
      ssr: false
    }
  )
);

const Login = withErrorBoundary(
  dynamic(() => import('@components/Login/Login'), {
    ssr: false
  })
);

const Player: React.ComponentType<{
  setIsPlayerExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  standalone?: boolean;
}> = withErrorBoundary(
  dynamic(() => import('@components/Player/Player'), {
    ssr: false
  })
);

const RadioPopup: React.ComponentType<{
  theme: any;
  modalContent: any;
  forbiddenList: any;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/RadioPopup'),
    {
      ssr: false
    }
  )
);

const HeaderSection: React.ComponentType<{
  section: any;
  backgroundShapes: any;
  imageSizes?: any;
  renderRte: (data: any) => any;
  basic?: boolean;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/HeaderSection'),
    {
      ssr: true
    }
  )
);

const HeaderSectionCurved: React.ComponentType<{
  content: any;
  imgOverlay: any;
  imageSet: any;
  renderRte: (data: any) => any;
}> = withErrorBoundary(
  dynamic(
    () =>
      import('@talpanetwork/talpa-publishing-components/HeaderSectionCurved'),
    {
      ssr: true
    }
  )
);

const HeaderSectionSlider: React.ComponentType<{
  svgHeaderCurve: any;
  slides: any[];
  renderRte: (data: any) => any;
}> = withErrorBoundary(
  dynamic(
    () =>
      import('@talpanetwork/talpa-publishing-components/HeaderSectionSlider'),
    {
      ssr: true
    }
  )
);

const ECommerceAuctionWidget: React.ComponentType<{ content: any }> =
  withErrorBoundary(
    dynamic(
      () => import('@talpanetwork/talpa-publishing-components/AuctionWidget'),
      {
        ssr: false
      }
    )
  );

const SvgIcon: React.ComponentType<SvgIconProps> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/SvgIcon'), {
    ssr: false
  })
);

const SvgIcons: React.ComponentType<SvgIconProps> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/utils/SvgIcons'), {
    ssr: false
  })
);

const LinkOverlay: React.ComponentType<{
  itemId?: string;
  content?: {
    sys?: object;
    fields?: object;
  };
  classes?: object;
  children?: string | object | ((...args: any[]) => any | any[]);
  onClick?(...args: any[]): any;
  variant?: 'default' | 'wrapper';
  routeProp?: string;
  internalProp?: boolean;
  history?: object;
  className?: string;
  action?: any;
  title?: string;
  menuSocialLink?: string;
  footerSocialLink?: string;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-core/LinkOverlay'), {
    ssr: false
  })
);

const List: React.ComponentType<{
  showTitle?: boolean;
  items?: any[];
  moreReference?: object;
  moreText?: string;
  variant?: 'default' | 'article' | 'masonry' | 'mobile' | 'section';
  title?: string;
  classes?: object;
  className?: string;
  type?: '1x1' | '2x1' | '1x2' | '2x2';
  renderAudioEmbed?(...args: any[]): any;
  iconPosition?: string;
  id?: string;
  holderHeight?: string | number;
  category?: string;
  showLabelOnArticleCards?: string;
}> = withErrorBoundary(
  dynamic(() => import('@talpanetwork/talpa-publishing-components/List'), {
    ssr: false
  })
);
const PictureBuilder: React.ComponentType<{
  imageData: {
    url?: string;
    focus?: string;
    alt?: string;
    imageSet?: any[];
    contentType?: string;
  };
  className?: string;
}> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/PictureBuilder'),
    { ssr: false }
  )
);

const OverviewSkeleton: React.ComponentType<
  {
    isNewsletterBrand?: boolean;
  } & ThemeProps
> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/OverviewSkeleton'),
    { ssr: false }
  )
);

const ArticleSkeleton: React.ComponentType<
  {
    noRelatedArticles?: boolean;
  } & ThemeProps
> = withErrorBoundary(
  dynamic(
    () => import('@talpanetwork/talpa-publishing-components/ArticleSkeleton'),
    { ssr: false }
  )
);

export {
  Text,
  Banner,
  SearchPage,
  List,
  Calendar,
  ProgramSection,
  StationPicker,
  StationPickerSection,
  FragmentJackpotSection,
  VotingCard,
  VotingCardEmbed,
  UniversalVoting,
  UniversalVotingEmbed,
  Frequency,
  provider,
  SliderGallery,
  IFrame,
  ArticleComposite,
  content,
  Hidden,
  ArticleCard,
  TextFrame,
  Typography,
  CategoryComp as Category,
  SocialShare,
  EmbedVideo,
  SvgIcon,
  Masonry,
  ChartList,
  MasonrySection,
  NotFoundComponent,
  tealium,
  gigyaScreenSets,
  ImageSection,
  FAQComponent,
  CompositeList,
  Section,
  SearchListItem,
  VideoEmbedPlayer,
  Grid,
  Button,
  SearchBar,
  NoResults,
  Quote,
  SocialEmbed,
  VideoSection,
  LinkOverlay,
  ArticleHeader,
  RelatedArticlesList,
  RelatedArticles,
  ArticleSponsored,
  Helmet,
  ContentfulRichText,
  OverviewComponent,
  SearchPageCategories,
  ContentHolder,
  mapGraphQLResponsePage,
  getApplicationLandingPageData,
  useContentContext,
  Gigya,
  Tealium,
  addTealiumInstance,
  setApplicationDataFromJson,
  setAppRoute,
  getAppRoute,
  getVotingRoute,
  setVotingRoute,
  JssThemeProvider,
  generateTheme,
  fetchWithoutHeader,
  postWithoutHeader,
  generateRoute,
  injectSheet,
  PictureBuilder,
  getContentfulService,
  isOverviewPage,
  OverviewSkeleton,
  ArticleSkeleton,
  SliderGalleryImage,
  isGoogleBot,
  fetchOverviewBySlug,
  mapGraphQLResponse,
  setInitialApplicationValues,
  setOverviewMetaData,
  setArticleMetaData,
  setCategoryMetaData,
  setSearchMetaData,
  getPageData,
  getRouteData,
  setPageType,
  fetchArticlesByCategoryId,
  useStore,
  initializeStore,
  ContentContextProvider,
  BrowserHistoryContextProvider,
  Meta,
  Content,
  SingleSection,
  ArticleComponent,
  ArticleContent,
  attachDomaintoPath,
  JustIn,
  LoginOverlay,
  SvgIcons,
  IAuthAction,
  ChartListTop50,
  ChartListTop50Embed,
  AudioEmbedPlayer,
  AudioEmbed,
  NotificationPopup,
  Modal,
  Toaster,
  checkUser,
  setUser,
  unsetUser,
  onPlay,
  onStop,
  onStopAllExceptMe,
  setStationSlug,
  showScreenSet,
  isAuthorized,
  Login,
  Player,
  RadioPlayer,
  SidebarSkeleton,
  setArticleView,
  setStreamCategory,
  clearStreamInfo,
  truncateOneLine,
  HeaderSection,
  HeaderSectionCurved,
  HeaderSectionSlider,
  withSuspense,
  RadioPopup,
  ECommerceAuctionWidget,
  startPlayback,
  stopPlayback,
  externalMediaEnded,
  loadStation,
  loadMedia,
  showMiniPlayer,
  hideMiniPlayer,
  getPlaybackStatus,
  getMedia,
  radioplayerEventBus,
  UniversalVotingTextContextProvider,
  UniversalVotingContentContextProvider
};
