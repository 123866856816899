import { palette } from '../palette/Palette';

const HeaderSectionSlider = {
  arrowSvgHover: {
    '& circle': {
      fill: palette.secondary.first
    }
  },

  dotHoverSelected: {
    background: palette.secondary.first
  },

  dotHover: {
    background: palette.grey.sixth
  }
};

export default HeaderSectionSlider;
