import { gql } from '@apollo/client';

import { client } from '@services/UniversalApiApolloClient';

class StationsProvider {
  getStations() {
    return client.query({
      query: gql`
        {
          getStations(profile: "radio-brand-web", slug: "stations-radio-10") {
            items(profile: "") {
              title
              slug
              shortTitle
              images {
                title
                uri
                imageType
              }
            }
          }
        }
      `
    });
  }
}

const provider = new StationsProvider();
export default provider;
