import { palette } from '../../palette/Palette';

const TrackTile = {
  tile: {
    // Playlist History Styles tileRoundedCorners true=5% false=0
    borderRadius: '5%'
  },

  title: {
    // Playlist History Styles text1Color
    color: palette.grey.second,
    // Playlist History Styles  text1FontFace
    fontFamily: 'Radio10Regular',
    // playlist history styles text1FontSize
    fontSize: 13,
    // playlist history styles text1LineHeight
    lineHeight: 1.54,
    // playlist history styles text1FontStyle
    textTransform: 'none'
  },
  artist: {
    // Playlist History Styles text2Color
    color: palette.grey.second,
    // Playlist History Styles text2FontFace
    fontFamily: 'PoppinsRegular',
    // playlist history styles text2FontStyle
    textTransform: 'none',
    // playlist history styles text2FontSize
    fontSize: 12,
    // playlist history styles text2LineHeight
    lineHeight: 1.17
  },
  titleStations: {
    // stations list styles track.text1FontFace
    fontFamily: 'PoppinsBold',
    // stations list styles track.text1FontSize
    fontSize: 12,
    // stations list styles track.text1LineHeight
    lineHeight: 1.5
  },
  rankingPosition: {
    backgroundColor: palette.primary.first
  }
};

export default TrackTile;
