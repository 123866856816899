const TabPanel = {
  body: {
    // General styles only second value gutter=30
    padding: '0 30px'
  },
  bodySm: {
    // General styles only second value gutterSmall=30
    padding: '0 20px'
  }
};

export default TabPanel;
