import { palette } from '../palette/Palette';

const NotificationPopup = {
  wrapper: {
    background: palette.default.white,
    boxShadow: '0 23px 52px 0 rgba(0, 0, 0, 0.4)'
  },

  buttonDisagree: {
    background: palette.grey.seventh,
    color: palette.default.white
  },

  buttonAgree: {
    background: palette.secondary.second,
    color: palette.default.white
  },

  svgLogo: {
    width: 178,
    height: 82,
    viewBox: '0 0 200 100'
  },

  svgLogoSm: {
    width: 104,
    height: 60,
    viewBox: '0 0 104 60'
  },

  svgClose: {
    width: 40,
    height: 40
  },

  svgCloseSm: {
    width: 32,
    height: 32
  },

  svgTriangle: {
    width: 67,
    height: 31,
    viewBox: '0 0 67 31'
  },

  svgTriangleMd: {
    width: 45,
    height: 21
  },

  svgTriangleSm: {
    width: 32,
    height: 15
  }
};

export default NotificationPopup;
