import { palette } from '../palette/Palette';

const SearchPageCategories = {
  buttonControlSvgHover: {
    '& g circle:last-of-type': {
      fill: palette.secondary.first
    }
  },

  noCategoriesMessage: {
    color: palette.grey.second
  }
};

export default SearchPageCategories;
