import { palette } from '../../palette/Palette';

const PlayButton = {
  button: {
    // Controls Styles primaryButtonColor
    color: palette.primary.first,

    // Controls Styles primaryButtonSize
    width: 70,
    height: 70,

    // Controls Styles primaryButtonHoverColor
    hoverColor: palette.secondary.first,

    '& svg': {
      // Controls Styles primaryButtonSize
      width: 70,
      height: 70
    }
  },

  buttonMiniPlayer: {
    // Mobile Player Styles primaryButtonSize
    width: 45,
    height: 45,

    '& svg': {
      // Mobile Player Styles primaryButtonSize
      width: 45,
      height: 45
    }
  },

  pulseGradient: {
    first: `radial-gradient(50% 50% at 50% 50%, rgba(0, 227, 79, 0) 0%, rgba(0, 227, 79, 0) 61.46%, rgba(0, 227, 79, 0.486487) 81.77%, ${palette.primary.first} 100%);`,
    second: `radial-gradient(50% 50% at 50% 50%, rgba(0, 227, 79, 0) 0%, rgba(0, 227, 79, 0) 61.46%, rgba(0, 227, 79, 0.486487) 81.77%, ${palette.primary.first} 100%);`
  }
};

export default PlayButton;
