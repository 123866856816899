import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconPause = (
  <>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <circle cx="32" cy="32" r="32" />
        <circle fill={palette.primary.first} cx="32" cy="32" r="32" />
      </g>
      <path
        d="M45.62 19.813a1.378 1.378 0 0 1 1.36 1.618l-3.893 22.067a1.379 1.379 0 0 1-1.357 1.141h-8.275a1.38 1.38 0 0 1-1.359-1.62l3.89-22.067c.117-.659.69-1.139 1.36-1.139zm-15.075 0a1.382 1.382 0 0 1 1.359 1.619l-3.89 22.067a1.381 1.381 0 0 1-1.36 1.14H18.38a1.38 1.38 0 0 1-1.358-1.62l3.89-22.067c.118-.658.69-1.14 1.36-1.14z"
        fill={palette.default.white}
      />
    </g>
  </>
);

export default iconPause;
